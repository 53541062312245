import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./featureCategoryCard.scss"
import { Link } from "gatsby"

const featureCategoryCard = ({ title, slug, icon, description }) => {
  return (
    <Link to={`/${slug}`}>
      <div className="feature-category-card">
        <h4>{title}</h4>
        <img src={`/images/${slug}.svg`} className="img-dark" />
        <img src={`/images/${slug}-light.svg`} className="img-light" />
        <p>{description}</p>
      </div>
    </Link>
  )
}

export default featureCategoryCard
