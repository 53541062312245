import React from "react"
import SubHeader from "./subHeader"

const TableContacts = ({ title }) => {
  return (
    <SubHeader
      content={{
        title: title,
        text:
          "Contact us through our <a href='https://jettisupport.zendesk.com/hc/en-us' target='blank_'>support portal</a>",
      }}
      style={{ color: "" }}
    />
  )
}

export default TableContacts
