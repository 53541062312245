import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./mainHeader.scss"

const MainHeader = ({ title, style, anim, delay, duration }) => {
  return (
    <h1
      className={`main-header ${style || ""}`}
      data-sal={anim || "fade"}
      data-sal-delay={delay || "300"}
      data-sal-easing="ease"
      data-sal-duration={duration || "600"}
    >
      {ReactHtmlParser(title)}
    </h1>
  )
}

export default MainHeader
