import React from "react"
import Navbar from "../../components/navbar"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Header from "../../components/header"
import Head from "../../components/head"
import Layout from "../../components/layout"
import OnboardingCardSm from "../../components/onboardingCardSm"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { Link } from "gatsby"

const CreateProduct = () => {
  return (
    <Layout navbar="darkblue">
      <Head
        title="Changelog"
        description="Learn how to connect with multiple vendors, manage your inventory, sales, customers and more with our step-by-step tutorials"
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "darkblue",
          particles: true,
        }}
      />
      <Container style={{ classes: "container" }}>
        <div className="position-relative">
          <Header
            content={{
              title: "Product catalog",
              text:
                "Find out how our customers use Onport to help increase their sales and grow their retail businesses",
            }}
            style={{ color: "text-center" }}
          />
          <Link
            to="/onboarding"
            className="btnOn dark-bg"
            style={{ position: "absolute", top: "246px", left: "-14px" }}
          >
            ← Back
          </Link>
        </div>
      </Container>
      <Container style={{ classes: "container mb-5" }}>
        <div
          style={{
            background: "#F4F6F8",
            padding: "40px 0",
            margin: "2rem -15px 0 -15px",
          }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "1rem",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            How would you like to create{" "}
            <span style={{ color: "#3b77ce" }}>products</span> in Onport?
          </p>
          <p
            style={{ fontWeight: "300", marginTop: "8px", textAlign: "center" }}
          >
            There are different ways to create products in Onport:
          </p>
          <div className="row align-items-start justify-content-around mt-5">
            <div className="text-center col-12 col-md-4">
              <img
                src="https://taact-brand-assets.netlify.app/assets/icons/onport/download.svg"
                style={{ height: "48px", marginBottom: "16px" }}
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="300"
              />
              <a
                className="btn-onboarding"
                target="_blank"
                href="https://jettisupport.zendesk.com/hc/en-us/sections/4405433157522-Integrations"
              >
                <p
                  style={{
                    background: "#CEE0FB",
                    textTransform: "uppercase",
                    color: "#3b5170",
                    margin: "0 auto",
                    padding: "8px 16px",
                    width: "140px",
                    fontSize: ".6rem",
                    fontWeight: "bolder",
                    borderRadius: "8px",
                  }}
                >
                  Vendor’s store
                </p>
              </a>
              <p
                style={{
                  fontWeight: "300",
                  marginTop: "8px",
                  padding: "0 16px",
                }}
              >
                Import from a supported e-commerce store.
              </p>
            </div>
            <div className="text-center col-12 col-md-4">
              <img
                src="https://taact-brand-assets.netlify.app/assets/icons/onport/channel.svg"
                style={{ height: "48px", marginBottom: "16px" }}
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="300"
              />
              <a
                className="btn-onboarding"
                target="_blank"
                href="https://jettisupport.zendesk.com/hc/en-us/articles/360021109539-Product-Publishing#connecting-products-to-jetti-that-already-exist-in-your-store"
              >
                <p
                  style={{
                    background: "#CEE0FB",
                    textTransform: "uppercase",
                    color: "#3b5170",
                    margin: "0 auto",
                    padding: "8px 16px",
                    width: "140px",
                    fontSize: ".6rem",
                    fontWeight: "bolder",
                    borderRadius: "8px",
                  }}
                >
                  Marketplace
                </p>
              </a>
              <p
                style={{
                  fontWeight: "300",
                  marginTop: "8px",
                  padding: "0 16px",
                }}
              >
                In case you already have existing products published in your
                store that were not published through Onport
              </p>
            </div>
            <div className="text-center col-12 col-md-4">
              <img
                src="https://taact-brand-assets.netlify.app/assets/icons/onport/documents.svg"
                style={{ height: "48px", marginBottom: "16px" }}
                data-sal="slide-up"
                data-sal-delay="800"
                data-sal-easing="ease"
                data-sal-duration="300"
              />
              <a
                className="btn-onboarding"
                target="_blank"
                href="https://jettisupport.zendesk.com/hc/en-us/articles/4414996274194-Vendor-integration-Google-Sheets-URL-"
              >
                <p
                  style={{
                    background: "#CEE0FB",
                    textTransform: "uppercase",
                    color: "#3b5170",
                    margin: "0 auto",
                    padding: "8px 16px",
                    width: "140px",
                    fontSize: ".6rem",
                    fontWeight: "bolder",
                    borderRadius: "8px",
                  }}
                >
                  Spreadsheet
                </p>
              </a>
              <p
                style={{
                  fontWeight: "300",
                  marginTop: "8px",
                  padding: "0 16px",
                }}
              >
                You can create a Google Sheet using our template. The system
                will automatically fetch it, or you can do it through a CSV file
                and import it into Onport whenever you or your vendor add new
                product information.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-4 justify-content-between">
          <div
            className="col-12 col-md-6 text-center"
            style={{
              background: "#F4F6F8",
              padding: "40px 0",
              maxWidth: "550px",
            }}
            data-sal="slide-right"
            data-sal-delay="400"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <p
              style={{
                fontWeight: "700",
                marginTop: "8px",
                textTransform: "uppercase",
                color: "#3b77ce",
              }}
            >
              Retail and cost pricing
            </p>
            <p
              style={{ fontWeight: "300", marginTop: "8px", padding: "0 64px" }}
            >
              Even if you work with several currencies or your vendors are
              having a sale, you can manage your prices in Onport.
            </p>
            <img
              src="https://taact-brand-assets.netlify.app/assets/illustrations/payment.svg"
              style={{
                height: "160px",
                marginTop: "32px",
                marginBottom: "64px",
              }}
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-easing="ease"
              data-sal-duration="300"
            />
            <p
              style={{
                fontWeight: "700",
                fontSize: "1rem",
                marginTop: "8px",
                textAlign: "center",
              }}
            >
              How would you like to manage{" "}
              <span style={{ color: "#3b77ce" }}>retail and cost pricing</span>?
            </p>
            <p
              style={{
                fontWeight: "300",
                marginTop: "8px",
                textAlign: "center",
                padding: "0 24px",
              }}
            >
              You can choose to have a complete synchronization of the prices
              with your vendors, or you can manage them manually.
            </p>
            <div className="row mt-5 px-3">
              <div className="text-center col-12 col-md-6">
                <img
                  src="https://taact-brand-assets.netlify.app/assets/icons/onport/automate.svg"
                  style={{ height: "48px", marginBottom: "16px" }}
                  data-sal="slide-up"
                  data-sal-delay="900"
                  data-sal-easing="ease"
                  data-sal-duration="300"
                />
                <a
                  className="btn-onboarding"
                  target="_blank"
                  href="https://help.jetti.io/hc/en-us/sections/4405433157522-Integrations "
                >
                  <p
                    style={{
                      background: "#CEE0FB",
                      textTransform: "uppercase",
                      color: "#3b5170",
                      margin: "0 auto",
                      padding: "8px 16px",
                      width: "140px",
                      fontSize: ".6rem",
                      fontWeight: "bolder",
                      borderRadius: "8px",
                    }}
                  >
                    Automatically
                  </p>
                </a>
                <p style={{ fontWeight: "300", marginTop: "8px" }}>
                  From a vendor's supported e-commerce store.
                </p>
              </div>
              <div className="text-center col-12 col-md-6">
                <img
                  src="https://taact-brand-assets.netlify.app/assets/icons/onport/tax.svg"
                  style={{ height: "48px", marginBottom: "16px" }}
                  data-sal="slide-up"
                  data-sal-delay="900"
                  data-sal-easing="ease"
                  data-sal-duration="300"
                />
                <a
                  className="btn-onboarding"
                  target="_blank"
                  href="https://help.jetti.io/hc/en-us/articles/360021109259-Managing-price-lists"
                >
                  <p
                    style={{
                      background: "#CEE0FB",
                      textTransform: "uppercase",
                      color: "#3b5170",
                      margin: "0 auto",
                      padding: "8px 16px",
                      width: "140px",
                      fontSize: ".6rem",
                      fontWeight: "bolder",
                      borderRadius: "8px",
                    }}
                  >
                    Manually
                  </p>
                </a>
                <p style={{ fontWeight: "300", marginTop: "8px" }}>
                  In the Onport App, in the vendor portal or through a Google
                  sheet/CSV file.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 text-center"
            style={{
              background: "#F4F6F8",
              padding: "40px 0",
              maxWidth: "550px",
            }}
            data-sal="slide-left"
            data-sal-delay="400"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <p
              style={{
                fontWeight: "700",
                marginTop: "8px",
                textTransform: "uppercase",
                color: "#3b77ce",
              }}
            >
              Inventory
            </p>
            <p
              style={{ fontWeight: "300", marginTop: "8px", padding: "0 16px" }}
            >
              You can manage inventory via API and if you don’t have one, you
              can use ours. If you need to create products manually but still
              want to sync the inventory from Onport, you can do so.
            </p>
            <img
              src="https://taact-brand-assets.netlify.app/assets/illustrations/inventory.svg"
              style={{
                height: "160px",
                marginTop: "32px",
                marginBottom: "64px",
              }}
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-easing="ease"
              data-sal-duration="300"
            />
            <p
              style={{
                fontWeight: "700",
                fontSize: "1rem",
                marginTop: "8px",
                textAlign: "center",
              }}
            >
              How would you like to connect and update{" "}
              <span style={{ color: "#3b77ce" }}>inventory</span>?
            </p>
            <p
              style={{
                fontWeight: "300",
                marginTop: "8px",
                textAlign: "center",
                padding: "0 24px",
              }}
            >
              You can choose to have a complete synchronization of the inventory
              with your vendors or you can manage your stock manually.
            </p>
            <div className="row mt-5 px-3">
              <div className="text-center col-12 col-md-6">
                <img
                  src="https://taact-brand-assets.netlify.app/assets/icons/onport/automate.svg"
                  style={{ height: "48px", marginBottom: "16px" }}
                  data-sal="slide-up"
                  data-sal-delay="900"
                  data-sal-easing="ease"
                  data-sal-duration="300"
                />
                <a
                  className="btn-onboarding"
                  target="_blank"
                  href="https://jettisupport.zendesk.com/hc/en-us/sections/4405433157522-Integrations"
                >
                  <p
                    style={{
                      background: "#CEE0FB",
                      textTransform: "uppercase",
                      color: "#3b5170",
                      margin: "0 auto",
                      padding: "8px 16px",
                      width: "140px",
                      fontSize: ".6rem",
                      fontWeight: "bolder",
                      borderRadius: "8px",
                    }}
                  >
                    Automatically
                  </p>
                </a>
                <p style={{ fontWeight: "300", marginTop: "8px" }}>
                  From a vendor's supported e-commerce store.
                </p>
              </div>
              <div className="text-center col-12 col-md-6">
                <img
                  src="https://taact-brand-assets.netlify.app/assets/icons/onport/tax.svg"
                  style={{ height: "48px", marginBottom: "16px" }}
                  data-sal="slide-up"
                  data-sal-delay="900"
                  data-sal-easing="ease"
                  data-sal-duration="300"
                />
                <a
                  className="btn-onboarding"
                  target="_blank"
                  href="https://help.jetti.io/hc/en-us/articles/4415010184594-Different-ways-to-manage-inventory#heading-4"
                >
                  <p
                    style={{
                      background: "#CEE0FB",
                      textTransform: "uppercase",
                      color: "#3b5170",
                      margin: "0 auto",
                      padding: "8px 16px",
                      width: "140px",
                      fontSize: ".6rem",
                      fontWeight: "bolder",
                      borderRadius: "8px",
                    }}
                  >
                    Manually
                  </p>
                </a>
                <p style={{ fontWeight: "300", marginTop: "8px" }}>
                  In the Onport App, in the vendor portal or through a Google
                  sheet/CSV file.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6"></div>
          <OnboardingCardSm
            content={{
              slug: "shipping",
              category: "SHIPPING",
              step: "Next →",
              src:
                "https://taact-brand-assets.netlify.app/assets/illustrations/send.svg",
              title: "Shipping strategies",
            }}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default CreateProduct
