import React from "react"
import "../styles/index.scss"

const SectionLayout = props => {
  return (
    <div className="section-container">
      <div className="d-flex flex-column align-items-center justify-content-center h-100">
        {props.children}
      </div>
    </div>
  )
}

export default SectionLayout
