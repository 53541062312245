import React from "react"
import Container from "../components/new/container/container"
import MainHeader from "../components/new/mainHeader/mainHeader"
import SubHeader from "../components/new/subHeader/subHeader"
import Button from "../components/new/button/button"
import TitleCategory from "../components/new/titleCategory/titleCategory"
import Block from "../components/new/block/block"
import Paragraph from "../components/new/paragraph/paragraph"
import Grid from "../components/new/grid/grid"
import HubspotContactFormTest from "../components/hubspotContactFormTest"
import Head from "../components/head"
import { useStaticQuery, graphql } from "gatsby"
import FooterMin from "../components/new/footerMin/footerMin"
import { Link } from "gatsby"
import logo from "../assets/icons/logo.svg"
import BackgroundShape from "../components/backgroundShape"
import gad1 from "../assets/images/gad-1.svg"
import gad2 from "../assets/images/gad-2.svg"
import gad3 from "../assets/images/gad-3.svg"

export default function Dropship() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomer(filter: { featured: { eq: true } }, limit: 4) {
        edges {
          node {
            logo
            title
            featured
            url
          }
        }
      }
    }
  `)

  return (
    <div className="layout">
      <div className="content">
        <Head
          title="Multi-vendor Dropshipping Automation Platform"
          description="Our Dropshipping Automation software helps you connect with multiple vendors and manage all your marketplace operations from one centralized platform."
        />
        {/* <Container style='fluid brandDark'>
                    <Container style='text-center py-5'>
                        <NodeGarden />
                            <Block>
                                <MainHeader title='AUTOMATE YOUR</br><span class="blue">MULTI-VENDOR</span></br>MARKETPLACE' style='light' anim='slide-up'/>
                            </Block>
                    </Container>
                </Container> */}
        <div className="pt-4 ml-5">
          <Link to="/" className={"title"}>
            <img src={logo} className="logo mr-5" alt="logo" />
          </Link>
        </div>
        <BackgroundShape
          style={{
            shape: "clip-fixed-big overflow-hidden",
            color: "darkblue",
            particles: true,
          }}
        />
        <Container style={{ classes: "container position-relative" }}>
          <div
            style={{
              textAlign: "center",
              marginTop: "3rem",
              paddingBottom: "3rem",
            }}
          >
            <Block>
              <MainHeader
                title='AUTOMATE YOUR</br><span class="blue">MULTI-VENDOR</span> MARKETPLACE'
                style="light"
                anim="slide-up"
              />
            </Block>
          </div>
          <div
            className="d-flex align-items-center justify-content-between desktop-only"
            style={{ opacity: ".6" }}
          >
            <img
              src={require(`../assets/icons/platform/elastic-path.svg`)}
              style={{ width: "160px" }}
            />
            <img
              src={require(`../assets/icons/platform/shopify.svg`)}
              style={{ width: "120px" }}
            />
            <img
              src={require(`../assets/icons/platform/big-commerce.svg`)}
              style={{ width: "160px" }}
            />
            <img
              src={require(`../assets/icons/platform/paypal.svg`)}
              style={{ width: "130px" }}
            />
            <img
              src={require(`../assets/icons/platform/stripe.svg`)}
              style={{ width: "80px" }}
            />
            <img
              src={require(`../assets/icons/platform/shippo.svg`)}
              style={{ width: "140px" }}
            />
          </div>
        </Container>
        <Container style="pt-5 mt-5">
          <Block anim="slide-up" style="text-center mx-auto py-5">
            <SubHeader title="SCALE YOUR BUSINESS" style="dark" />
            <Paragraph
              text="Curate product catalogs, route orders, manage vendor commissions and automate shipping workflows"
              stle="xl"
            />
            {/* <Button href='/sales-contact' text="Book demo" style='btnOn light-bg py-3 px-4 my-5 font-weight-bold' anim='slide-down'/> */}
            <a
              href="/2022_Q4_google_ads_onport_dropship/#hubspot"
              className="btnOn light-bg py-3 px-4 my-5 font-weight-bold"
              style={{ fontSize: "1.2rem" }}
            >
              Book Demo
            </a>
          </Block>
          <Grid style="grid-2">
            <Block anim="slide-up">
              <SubHeader title="Peace of mind" style="dark" />
              <Paragraph
                text="Alleviate your pain points so that you can focus on scaling your business"
                style="mb-2"
              />
              <Button
                href="/use-cases/automate-marketplace-operations"
                text="Learn more →"
                style="inline-text"
              />
            </Block>
            <Block style="w-100 h-400 overflow-hidden">
              <img
                src={gad1}
                style={{
                  borderRadius: "24px",
                  padding: "1rem",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                data-sal="slide-left"
                data-sal-duration="600"
              />
            </Block>
          </Grid>
          <Grid style="grid-2">
            <Block style="w-100 h-400 overflow-hidden">
              <img
                src={gad2}
                style={{
                  borderRadius: "24px",
                  padding: "1rem",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                data-sal="slide-left"
                data-sal-duration="600"
              />
            </Block>
            <Block anim="slide-up" style="pl-4">
              <SubHeader title="Deploy faster" style="dark" />
              <Paragraph
                text="Leverage a wide range of pre-built integrations to hit the ground running"
                style="mb-2"
              />
              <Button
                href="/use-cases/create-a-marketplace"
                text="Learn more →"
                style="inline-text"
              />
            </Block>
          </Grid>
          <Grid style="grid-2 mb-4">
            <Block anim="slide-up">
              <SubHeader title="Adaptive to your needs" style="dark" />
              <Paragraph
                text="Connect with our open API to integrate all of your systems into one platform"
                style="mb-2"
              />
              <Button
                href="/use-cases/expand-product-offering"
                text="Learn more →"
                style="inline-text"
              />
            </Block>
            <Block style="w-100 h-400 overflow-hidden">
              <img
                src={gad3}
                style={{
                  borderRadius: "24px",
                  padding: "1rem",
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                }}
                data-sal="slide-left"
                data-sal-duration="600"
              />
            </Block>
          </Grid>
        </Container>
        <Container style="fluid brandDark">
          <Container style="py-5 mt-5 text-center">
            <TitleCategory title="ADAPTIVE TECH" style="lightBlue" />
            <SubHeader title="Agility for your solution" style="light" />
            <Paragraph
              text="Experience all-in-on product catalogs, order routing, payments, and  automated shipping workflows with Onport.</br>Are you ready to take ownership of the digital space?"
              style="light w-75 mx-auto"
            />
            <Grid style="grid-4 mt-5">
              {data.allContentfulCustomer.edges.map(edge => {
                if (edge.node.featured) {
                  return (
                    <a href={edge.node.url} target="_blank">
                      <img
                        src={edge.node.logo}
                        className="about-logo about-logo__home-inv"
                        alt={edge.node.title}
                        style={{
                          opacity: ".4",
                          maxWidth: "160px",
                          height: "auto",
                        }}
                      />
                    </a>
                  )
                }
              })}
            </Grid>
            <a
              href="/2022_Q4_google_ads_onport_dropship/#hubspot"
              className="btnOn light-bg py-3 px-4 mt-5 font-weight-bold"
              style={{ fontSize: "1.2rem" }}
            >
              Book Demo
            </a>
          </Container>
        </Container>
        <Container style="" anim="fade">
          <div className="w-75 my-5 py-4 mx-auto" id="hubspot">
            <HubspotContactFormTest />
          </div>
        </Container>
      </div>
      <FooterMin />
    </div>
  )
}
