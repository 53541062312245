import React from "react"

const TableEntryShipping = ({ data }) => {
  const generateEntry = data => {
    if (data === "Yes") {
      return <p className="entry entry-success">Yes</p>
    } else if (data === "No") {
      return <p className="entry entry-warning">No</p>
    }
  }

  return (
    <div
      className="shipping-table-entry"
      data-sal="slide-up"
      data-sal-easing="ease"
      data-sal-delay="100"
      data-sal-duration="800"
    >
      <div>
        <img
          className="entry-logo"
          src={require(`../assets/icons/platform/round/${data.title}.svg`)}
        />
      </div>
      <div>{generateEntry(data.labels)}</div>
      <div>{generateEntry(data.trackingSync)}</div>
      <div>{generateEntry(data.signature)}</div>
      <div>{generateEntry(data.pickup)}</div>
      <div>{generateEntry(data.insurance)}</div>
      <div>{generateEntry(data.multiParcelShipping)}</div>
      <div>{generateEntry(data.international)}</div>
    </div>
  )
}

export default TableEntryShipping
