import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/navbar"
import Header from "../components/header"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Layout from "../components/layout"
import ReactHtmlParser from "react-html-parser"
import Head from "../components/head"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import HubspotContactFormPartner from "../components/hubspotContactFormPartner"
import { MDXProvider } from "@mdx-js/react"

export const query = graphql`
  query($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      title
      slug
      image
      summary
      document
      publishedAt(formatString: "MMM Do, YYYY")
      contentText {
        childMdx {
          body
        }
      }
      author {
        name
        image {
          file {
            url
          }
        }
      }
    }
  }
`

const CaseStudy = props => {
  return (
    <Layout navbar="transparent">
      <Head
        title={"" + props.data.contentfulCaseStudy.title}
        description={props.data.contentfulCaseStudy.summary}
        img={props.data.contentfulCaseStudy.image}
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "bg-case-sm",
          particles: false,
        }}
      />
      <Container style={{ classes: "container" }}>
        <Header
          content={{ title: props.data.contentfulCaseStudy.title }}
          style={{ color: "text-center" }}
        />
      </Container>
      <Container style={{ classes: "container blog-post mb-5 pb-5" }}>
        {/*          <img src={props.data.contentfulCaseStudy.image} className="blog-post__img"/>
         */}{" "}
        <div className="blog-post__text">
          <MDXProvider>
            <MDXRenderer>
              {props.data.contentfulCaseStudy.contentText.childMdx.body}
            </MDXRenderer>
          </MDXProvider>
          <div className="mt-5 pt-5">
            <HubspotContactFormPartner
              id={props.data.contentfulCaseStudy.document}
            />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default CaseStudy
