import React from "react"
import Container from "../../../components/container"
import Head from "../../../components/head"
import { getNames } from "country-list"
import NodeGarden from "../../../components/new/nodeGarden/nodeGarden"
import Grid from "../../../components/new/grid/grid"
import Layout from "../../../components/new/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../../components/new/paragraph/paragraph"
import SubHeader from "../../../components/new/subHeader/subHeader"
import HubspotContactFormPartner from "../../../components/hubspotContactFormPartner"

export default function BookDemo() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomer(filter: { featured: { eq: true } }, limit: 4) {
        edges {
          node {
            logo
            title
            featured
            url
          }
        }
      }
    }
  `)
  const countries = getNames()
  return (
    <Layout navbar="darkerblue">
      <Head
        title="Transform Your Marketplace Operations Now"
        description="Ensure the future of your marketplace by booking a demo of the Onport Platform now."
      />
      <Container style={{ classes: "container-fluid bg-contact-lg py-5" }}>
        <Container style={{ classes: "container position-relative" }}>
          <div className="row mt-5 pt-5 align-items-start">
            <div className="col-12 col-md-6 py-5 mt-5">
              <SubHeader
                title="Talk to an Onport marketplace expert"
                style="light"
              />
              <Paragraph
                text="Discover how the Onport multi-vendor marketplace solution can deliver on your business needs."
                style="light w-75"
              />
            </div>
            <div className="col-12 col-md-6 bg-lol2 pt-4">
              <h2 className="text-white pl-4" style={{ fontWeight: "bold" }}>
                Take the first step today
              </h2>
              <HubspotContactFormPartner id="474ab4f8-53df-41db-9cbd-a47b7c187e03" />
            </div>
          </div>
          <Grid style="grid-4 mt-5 py-4">
            {data.allContentfulCustomer.edges.map(edge => {
              if (edge.node.featured) {
                return (
                  <a href={edge.node.url} target="_blank">
                    <img
                      src={edge.node.logo}
                      alt={edge.node.title}
                      style={{
                        opacity: "0.6",
                        width: "160px",
                        filter: "invert(100%) saturate(0)",
                      }}
                    />
                  </a>
                )
              }
            })}
          </Grid>
        </Container>
      </Container>
    </Layout>
  )
}
