import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/navbar"
import Header from "../components/header"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Layout from "../components/layout"
import Head from "../components/head"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import HubspotContactFormPartner from "../components/hubspotContactFormPartner"
import { MDXProvider } from "@mdx-js/react"

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      title
      slug
      image
      summary
      formId
      publishedAt(formatString: "MMM Do, YYYY")
      content {
        childMdx {
          body
        }
      }
      author {
        name
        image {
          file {
            url
          }
        }
      }
    }
  }
`

const BlogPost = props => {
  const content = props.data.contentfulBlog.content.childMdx
    ? props.data.contentfulBlog.content.childMdx.body
    : ""

  return (
    <Layout navbar="transparent">
      <Head
        title={"" + props.data.contentfulBlog.title}
        description={props.data.contentfulBlog.summary}
        img={props.data.contentfulBlog.image}
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "bg-partners-sm",
          particles: false,
        }}
      />
      <Container style={{ classes: "container" }}>
        <Header
          content={{ title: props.data.contentfulBlog.title }}
          style={{ color: "text-center" }}
        />
        <div className="blog-post-card__author blog-post-card__author-alt ">
          <img
            src={props.data.contentfulBlog.author?.image?.file?.url}
            className="blog-post-card__author-img"
            alt="author-img"
          />
          <div className="ml-2">
            <p className="blog-post-card__author-name">
              {props.data.contentfulBlog.author?.name}
            </p>
            <p className="blog-post-card__date">
              {props.data.contentfulBlog.publishedAt}
            </p>
          </div>
        </div>
      </Container>
      <Container style={{ classes: "container blog-post mb-5 pb-5" }}>
        {/*          <img src={props.data.contentfulBlog.image} className="blog-post__img"/>
         */}{" "}
        <div className="blog-post__text">
          <MDXProvider>
            <MDXRenderer>{content}</MDXRenderer>
          </MDXProvider>
          <div className="mt-5 pt-5">
            <HubspotContactFormPartner
              id={props.data.contentfulBlog.formId || "none"}
            />
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default BlogPost
