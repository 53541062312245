import React from "react"
import { graphql } from "gatsby"
import UseCaseHeader from "../components/new/useCaseHeader/useCaseHeader"
import UseCaseSection from "../components/new/useCaseSection/useCaseSection"
import Container from "../components/new/container/container"
import Layout from "../components/new/layout/layout"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"
import HubspotContactFormPartner from "../components/hubspotContactFormPartner"
import Head from "../components/head"
import Paragraph from "../components/new/paragraph/paragraph"
import SubHeader from "../components/new/subHeader/subHeader"

export const query = graphql`
  query($slug: String!) {
    contentfulUseCase(slug: { eq: $slug }) {
      slug
      title
      subtitle
      metaTitle
      metaDescription
      imageHeader
      sections {
        title
        description
        image
        order
      }
      formTitle
      formSubtitle
      formId
      quoteImage
    }
  }
`

const useCase = props => {
  return (
    <Layout navbar="transparent">
      <Head
        title={props.data.contentfulUseCase.metaTitle}
        description={props.data.contentfulUseCase.metaDescription}
        img="https://imgur.com/HzXbH51.png"
      />
      <UseCaseHeader
        title={props.data.contentfulUseCase.title}
        subtitle={props.data.contentfulUseCase.subtitle}
        image={props.data.contentfulUseCase.imageHeader}
      />
      <Container style="desktop-only">
        {props.data.contentfulUseCase.sections.map(node => {
          return (
            <UseCaseSection
              title={node.title}
              description={node.description}
              image={node.image}
              order={node.order}
            />
          )
        })}
      </Container>
      <Container style="mobile-only">
        {props.data.contentfulUseCase.sections.map(node => {
          return (
            <UseCaseSection
              title={node.title}
              description={node.description}
              image={node.image}
              order="1"
            />
          )
        })}
      </Container>
      <div
        id="form"
        style={{
          backgroundImage: `url(${props.data.contentfulUseCase.imageHeader})`,
          backgroundSize: "cover",
          padding: "3rem 0",
        }}
      >
        <Container>
          <div className="row mt-5 align-items-start">
            <div className="desktop-only col-md-6">
              <SubHeader
                title={props.data.contentfulUseCase.formTitle}
                style="light"
              />
              <Paragraph
                text={props.data.contentfulUseCase.formSubtitle}
                style="light w-75 font-weight-bold l"
              />
              <img
                src={props.data.contentfulUseCase.quoteImage}
                className="w-100 pr-5 mt-5"
              />
            </div>
            <div
              className="col-12 mobile-only"
              style={{ margin: "0 auto", textAlign: "center" }}
            >
              <SubHeader
                title={props.data.contentfulUseCase.formTitle}
                style="light"
              />
              <Paragraph
                text={props.data.contentfulUseCase.formSubtitle}
                style="light"
              />
              <img
                src={props.data.contentfulUseCase.quoteImage}
                className="w-100 mt-5"
              />
            </div>
            <div className="col-12 col-md-6 bg-lol2 pt-4">
              <p
                className="text-white pl-4"
                style={{ fontWeight: "600", fontSize: "16px" }}
              >
                Complete the form and we'll send you a calendar link
              </p>
              <HubspotContactFormPartner
                id={props.data.contentfulUseCase.formId}
              />
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default useCase
