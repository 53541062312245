import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./titleCategory.scss"

const titleCategory = ({ title, style, anim, delay, duration }) => {
  return (
    <h4
      className={`title-category ${style || ""}`}
      data-sal={anim}
      data-sal-delay={delay || "300"}
      data-sal-easing="ease"
      data-sal-duration={duration || "600"}
    >
      {ReactHtmlParser(title)}
    </h4>
  )
}

export default titleCategory
