import React from "react"
import "./onboardingBlock.scss"

const onboardingBlock = ({ icon, link, title, description }) => {
  return (
    <div className="text-center">
      {icon ? (
        <img
          src={`https://taact-brand-assets.netlify.app/assets/icons/onport/${icon}.svg`}
          style={{ height: "48px", marginBottom: "16px" }}
          data-sal="slide-up"
          data-sal-delay="400"
          data-sal-easing="ease"
          data-sal-duration="300"
        />
      ) : (
        ""
      )}
      {link ? (
        <a className="btn-onboarding" target="_blank" href={link}>
          <p
            style={{
              background: "#CEE0FB",
              textTransform: "uppercase",
              color: "#3b5170",
              margin: "0 auto",
              padding: "8px 16px",
              width: "140px",
              fontSize: ".6rem",
              fontWeight: "bolder",
              borderRadius: "8px",
            }}
          >
            {title}
          </p>
        </a>
      ) : (
        <p
          style={{
            background: "#CEE0FB",
            textTransform: "uppercase",
            color: "#3b5170",
            margin: "0 auto",
            padding: "8px 16px",
            width: "140px",
            fontSize: ".6rem",
            fontWeight: "bolder",
            borderRadius: "8px",
          }}
        >
          {title}
        </p>
      )}
      <p style={{ fontWeight: "300", marginTop: "8px", padding: "0 16px" }}>
        {description}
      </p>
    </div>
  )
}

export default onboardingBlock
