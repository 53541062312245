import React from "react"
import "./block.scss"

const Block = ({ children, style, anim, delay, duration }) => {
  return (
    <div
      className={`block ${style || ""}`}
      data-sal={anim}
      data-sal-delay={delay || "300"}
      data-sal-easing="ease"
      data-sal-duration={duration || "600"}
    >
      {children}
    </div>
  )
}

export default Block
