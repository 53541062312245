import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/navbar"
import Header from "../components/header"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import FeaturesCardMd from "../components/featureCardMd"
import Layout from "../components/layout"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulFeature(slug: { eq: $slug }) {
      title
      slug
      metaDescription {
        metaDescription
      }
      image
      description {
        childMdx {
          body
        }
      }
      summary {
        summary
      }
    }
    allContentfulFeature {
      edges {
        node {
          title
          slug
          icon
        }
      }
    }
  }
`

const Feature = props => {
  return (
    <Layout navbar="transparent">
      <Head
        title={"" + props.data.contentfulFeature.title}
        description={
          props.data.contentfulFeature?.metaDescription?.metaDescription ||
          "Find out how our customers use Onport to help increase their sales and grow their retail businesses"
        }
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "bg-enterprise-sm",
          particles: false,
        }}
      />
      <Container style={{ classes: "container" }}>
        <Header
          content={{
            title: props.data.contentfulFeature.title,
            text:
              props.data.contentfulFeature.summary?.summary ||
              "Find out how our customers use Onport to help increase their sales and grow their retail businesses",
          }}
          style={{ color: "text-center" }}
        />
        {/*              <img src={icon} className="feature-icon test-int" />
         */}{" "}
      </Container>
      <Container style={{ classes: "container mb-5" }}>
        <div className="feature-description">
          <p>
            {props.data.contentfulFeature.description ? (
              <MDXProvider>
                <MDXRenderer>
                  {props.data.contentfulFeature.description.childMdx.body}
                </MDXRenderer>
              </MDXProvider>
            ) : (
              props.data.contentfulFeature.summary
            )}
          </p>
          <img
            src={
              props.data.contentfulFeature.image ||
              "https://taact-brand-assets.netlify.app/assets/screenshots/inhouse-fullfilment.jpg"
            }
            className="feature-img shadow desktop-only"
            alt={props.data.contentfulFeature.title}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default Feature
