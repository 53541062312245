import React from "react"
import Container from "../../components/container"
import Head from "../../components/head"
import NodeGarden from "../../components/new/nodeGarden/nodeGarden"
import Grid from "../../components/new/grid/grid"
import Layout from "../../components/new/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../components/new/paragraph/paragraph"
import HubspotContactFormPartner from "../../components/hubspotContactFormPartner"

export default function GetPricing() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomer {
        edges {
          node {
            logo
            title
            featured
            url
            slug
          }
        }
      }
    }
  `)
  return (
    <Layout navbar="darkerblue">
      <Head
        title="Transform Your Marketplace Operations Now"
        description="Ensure the future of your marketplace by booking a demo of the Onport Platform now."
      />
      <Container style={{ classes: "container-fluid bg-contact-lg py-5" }}>
        <Container style={{ classes: "container position-relative" }}>
          <div className="row mt-5 pt-5 align-items-start">
            <div className="col-12 col-md-6 mt-4">
              <h2 className="text-white" style={{ fontWeight: "bold" }}>
                We have adaptable pricing solutions designed to grow with your
                multi-vendor marketplace needs.
              </h2>
              <Paragraph
                text="
								· <b>Tailored Solutions</b>: We will assess your stage to present pricing  proposals and technical solutions adapted to your needs.<br><br>
								· <b>Adjustable Pricing</b>: Our pricing adjusts to your business size, scaling with you to deliver the best value regardless of your stage.<br><br>
								· <b>Partnership Approach</b>: We will offer ongoing support and strategic insights so that you can make the best decisions for your business. <br><br>
								· <b>Scalable Support</b>: Our success and support teams will work with you to adjust the pricing as you keep scaling your business.<br><br>
								· <b>Adaptable Technology</b>: Our platform was  built to ensure you can focus on growing the business instead of managing manual tasks.<br><br>
								Get in touch to learn more about how Onport can help you launch or scale your business."
                style="light w-75 l mt-4"
              />
              <div
                className="mt-5 text-white"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  padding: "1rem",
                  borderRadius: "8px",
                }}
              >
                <p
                  className="quote mb-4"
                  style={{ fontStyle: "italic", fontSize: "1.2rem" }}
                >
                  Onport became an essential partner as we scaled, its
                  flexibility allowed us to adapt the platform to meet our
                  evolving needs, from managing supplier information to handling
                  complex shipping logistics.
                </p>
                <div className="d-flex align-items-center">
                  <img
                    src="https://media.licdn.com/dms/image/v2/D5603AQGv9bHSeW7ZXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669900508421?e=2147483647&v=beta&t=y_y5gPgiS81Fpl_RwHrSnjDoNIjk7eKUkuuJq9xZoto"
                    style={{
                      height: "60px",
                      width: "60px",
                      borderRadius: "100%",
                      objectFit: "cover",
                      filter: "grayscale(100%)",
                    }}
                  />
                  <div className="ml-3">
                    <p style={{ fontWeight: "900", fontSize: "1rem" }}>
                      Nicole Murphy
                    </p>
                    <p style={{ fontSize: "1rem" }}>
                      Co-Founder & CEO | Tall Size
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 bg-lol2 pt-4">
              <h2 className="text-white pl-4" style={{ fontWeight: "bold" }}>
                Take the first step today
              </h2>
              <HubspotContactFormPartner id="a1eda0e8-5988-4430-a25f-21f7b0c55874" />
            </div>
          </div>
          <Grid style="grid-4 mt-5 py-4">
            {data.allContentfulCustomer.edges.map(edge => {
              if (
                edge.node.slug === "and-open" ||
                edge.node.slug === "stadium-goods" ||
                edge.node.slug === "terminal-x" ||
                edge.node.slug === "maavee"
              ) {
                return (
                  <a href={edge.node.url} target="_blank">
                    <img
                      src={edge.node.logo}
                      alt={edge.node.title}
                      style={{
                        opacity: "0.6",
                        maxHeight: "160px",
                        maxWidth: "200px",
                        filter: "invert(100%) saturate(0)",
                      }}
                    />
                  </a>
                )
              }
            })}
          </Grid>
        </Container>
      </Container>
    </Layout>
  )
}
