import Intercom from "@intercom/messenger-js-sdk"
import React from "react"
import { Helmet } from "react-helmet"

const Head = ({ title, description, img }) => {
  Intercom({
    app_id: "uh5hafpw",
  })
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:image" content={img || ""} />
      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <script type="text/javascript">
        {` 
          (function() {
              var t = document.createElement('script'),
                  s = document.getElementsByTagName('script')[0];
              t.async = true;
              t.id    = 'cio-forms-handler';
              t.setAttribute('data-site-id', 'c763ad864e9e08d03a4d');
              t.setAttribute('data-base-url', 'https://customerioforms.com');
              t.src = 'https://customerioforms.com/assets/forms.js';
              s.parentNode.insertBefore(t, s);
            })();
        `}
      </script>
      <script type="text/javascript">
        {` 
          (function (c, p, d, u, id, i) {
            id = ''; // Optional Custom ID for user in your system
            u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p) + '&e=' + id;
            i = document.createElement('script');
            i.type = 'application/javascript';
            i.async = true;
            i.src = u;
            d.getElementsByTagName('head')[0].appendChild(i);
          }("1009496", document.location.href, document));
        `}
      </script>
      <script type="text/javascript">
        {` 
          !function () {var reb2b = window.reb2b = window.reb2b || []; if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"]; reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments); args.unshift(method);reb2b.push(args);return reb2b;};}; for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);} reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true; script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz"; var first = document.getElementsByTagName("script")[0]; first.parentNode.insertBefore(script, first);}; reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("4O7Z0HMMJ9NX");}();
        `}
      </script>
      <script type="text/javascript">
        {` 
          !function(){var e="dreamdata",dreamdata=window[e]=window[e]||[];if(!dreamdata.initialize)if(dreamdata.invoked)window.console&&console.error&&console.error("Dreamdata snippet included twice.");else{dreamdata.invoked=!0,dreamdata.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"],dreamdata.factory=function(t){return function(){if(window[e].initialized)return window[e][t].apply(window[e],arguments);var a=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(t)>-1){var r=document.querySelector("link[rel='canonical']");a.push({__t:"bpc",c:r&&r.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}return a.unshift(t),dreamdata.push(a),dreamdata}};for(var t=0;t<dreamdata.methods.length;t++){var a=dreamdata.methods[t];dreamdata[a]=dreamdata.factory(a)}dreamdata.load=function(t,a){var r=document.createElement("script");r.id="dreamdata-analytics",r.type="text/javascript",r.async=!0,r.setAttribute("data-global-dreamdata-analytics-key",e),r.src="https://cdn.dreamdata.cloud/scripts/analytics/next/dreamdata.min.js";var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i),dreamdata._loadOptions=a},dreamdata._writeKey="0b8c13e7-77e5-43dd-9dc7-e72d135244e0",dreamdata.SNIPPET_VERSION="dreamdata-2.0.1",dreamdata.load("0b8c13e7-77e5-43dd-9dc7-e72d135244e0", {"formTracking":{"html":true,"hubspot":true},"intentSources":{"autoGroup":true}}),dreamdata.page()}}();        `}
      </script>
      <script type="text/javascript">
        {` 
            !function(){if(!window.dreamdata||!window.dreamdata.initialized){if(window.dreamdata){var e=document.getElementById("dreamdata-analytics");if(e&&e.type&&"text/javascript"==e.type)return}var a="dreamdata-cl",dreamdata=window[a]=window[a]||[];if(!dreamdata.initialize)if(dreamdata.invoked)window.console&&console.error&&console.error("Dreamdata CL snippet included twice.");else{dreamdata.invoked=!0,dreamdata.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"],dreamdata.factory=function(e){return function(){if(window[a].initialized)return window[a][e].apply(window[a],arguments);var t=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var r=document.querySelector("link[rel='canonical']");t.push({__t:"bpc",c:r&&r.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}return t.unshift(e),dreamdata.push(t),dreamdata}};for(var t=0;t<dreamdata.methods.length;t++){var r=dreamdata.methods[t];dreamdata[r]=dreamdata.factory(r)}dreamdata.load=function(e,t){var r=document.createElement("script");r.id="dreamdata-analytics-cl",r.type="text/javascript",r.async=!0,r.setAttribute("data-global-dreamdata-cl-analytics-key",a),r.src="https://cdn.drda.io/scripts/analytics/next/dreamdata.cl.min.js";var i=document.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i),dreamdata._loadOptions=t},dreamdata._writeKey="0b8c13e7-77e5-43dd-9dc7-e72d135244e0",dreamdata.SNIPPET_VERSION="dreamdata-cl-2.0.0",dreamdata.load("0b8c13e7-77e5-43dd-9dc7-e72d135244e0"),dreamdata.page()}}}();        `}
      </script>
      {/* <script>
        {`
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NZ6RVT3');</script>
        `}
      </script> */}
    </Helmet>
  )
}

export default Head
