import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./title.scss"

const title = ({ title, style, anim, delay, duration }) => {
  return (
    <h3
      className={`titleOn ${style || ""}`}
      data-sal={anim}
      data-sal-delay={delay || "300"}
      data-sal-easing="ease"
      data-sal-duration={duration || "600"}
    >
      {ReactHtmlParser(title)}
    </h3>
  )
}

export default title
