import React from "react"
import ReactHtmlParser from "react-html-parser"

const SubHeaderHome = props => {
  return (
    <div
      className={`sub-header-home ${props.style.color}`}
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration="600"
    >
      <h2 className="sub-header-home__title">{props.content.title}</h2>
      <p className="sub-header-home__subtitle">
        {ReactHtmlParser(props.content.text)}
      </p>
    </div>
  )
}

export default SubHeaderHome
