import React from "react"
import Navbar from "../components/navbar"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Header from "../components/header"
import Head from "../components/head"
import Layout from "../components/layout"
import FaqCard from "../components/faqCard"
import { graphql, useStaticQuery } from "gatsby"

export default function Faqs() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(filter: { category: { eq: "General" } }) {
        edges {
          node {
            question
            category
            answer {
              childMdx {
                body
              }
            }
          }
        }
      }
    }
  `)

  let counter = 0

  return (
    <Layout navbar="transparent">
      <Head
        title="Frequently Asked Questions About Onport"
        description="Have some questions for us? Explore our FAQs to better understand how Onport can fit into your marketplace operations."
      />

      <BackgroundShape
        style={{ shape: "clip", color: "bg-blog-lg", particles: false }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{
              title: "Frequently Asked Questions",
              text:
                "Find out how our customers use Onport to help increase their sales and grow their retail businesses",
            }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container" }}>
        {data.allContentfulFaq.edges.map(edge => {
          counter += 1
          return (
            <FaqCard
              content={{
                question: edge.node.question,
                answer: edge.node.answer.childMdx.body,
                counter: counter,
              }}
            />
          )
        })}
      </Container>
    </Layout>
  )
}
