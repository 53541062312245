import React from "react"
import Navbar from "../components/navbar"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Header from "../components/header"
import SubHeader from "../components/subHeader"
import Head from "../components/head"
import Layout from "../components/layout"
import FaqCard from "../components/faqCard"
import { graphql, useStaticQuery } from "gatsby"

export default function FaqsRebranding() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(
        filter: { category: { in: ["Rebranding", "Technical"] } }
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            question
            category
            createdAt
            answer {
              childMdx {
                body
              }
            }
          }
        }
      }
    }
  `)

  let counter = 0

  return (
    <Layout navbar="transparent">
      <Head title="Rebrand FAQs" description="Acquisitin & Rebrand FAQ's" />

      <BackgroundShape
        style={{ shape: "clip", color: "bg-blog-lg", particles: false }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{
              title: "Acquisition & Rebrand FAQ's",
              text:
                "Why Farfetch is a great partner and Onport Rebranding to Onport",
            }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container" }}>
        {data.allContentfulFaq.edges.map(edge => {
          if (edge.node.category === "Rebranding") {
            counter += 1
            return (
              <FaqCard
                content={{
                  question: edge.node.question,
                  answer: edge.node.answer.childMdx.body,
                  counter: counter,
                }}
              />
            )
          }
        })}
      </Container>
      <Container style={{ classes: "container" }}>
        <SubHeader
          content={{ title: "Technical FAQs", text: "" }}
          style={{ color: "" }}
        />
        {data.allContentfulFaq.edges.map(edge => {
          if (edge.node.category === "Technical") {
            counter += 1
            return (
              <FaqCard
                content={{
                  question: edge.node.question,
                  answer: edge.node.answer.childMdx.body,
                  counter: counter,
                }}
              />
            )
          }
        })}
      </Container>
    </Layout>
  )
}
