import BackgroundShape from "../components/backgroundShape"
import CardFeature from "../components/cardFeature"
import Container from "../components/new/container/container"
import FeatureImg from "../components/new/featureImg/featureImg"
import Layout from "../components/layout"
import Head from "../components/head"
import Header from "../components/header"
import { graphql, Link } from "gatsby"
import Grid from "../components/new/grid/grid"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"
import React, { useEffect, useState } from "react"

export const query = graphql`
  query($slug: String!) {
    contentfulFeatureCategory(slug: { eq: $slug }) {
      title
      slug
      description
      order
      icon
      summary {
        childMdx {
          body
        }
      }
      feature {
        title
        image
        metaTitle
        metaDescription {
          metaDescription
        }
        description {
          childMdx {
            body
          }
        }
        slug
        summary {
          summary
        }
      }
    }
    allContentfulFeatureCategory {
      edges {
        node {
          title
          slug
          order
        }
      }
    }
  }
`

const FeatureLayout = props => {
  const [enlargedSrc, setEnlargedSrc] = useState(null)

  useEffect(() => {
    // Select all images inside .summary container
    const summaryDiv = document.querySelector(".testname")
    if (!summaryDiv) return

    const images = summaryDiv.querySelectorAll("img")

    const handleImageClick = event => {
      setEnlargedSrc(event.target.src)
    }

    // Add click event listener to each image
    images.forEach(img => img.addEventListener("click", handleImageClick))

    // Cleanup event listeners when component unmounts
    return () => {
      images.forEach(img => img.removeEventListener("click", handleImageClick))
    }
  }, []) // Empty dependency array to run once on mount

  const handleClose = () => {
    setEnlargedSrc(null)
  }

  return (
    <Layout navbar="transparent">
      <Head
        title="Partners"
        description="Our cloud based software helps you connect with multiple vendors and manage all your ecommerce operations from a single platform"
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed",
          color: "bg-enterprise-sm",
          particles: false,
        }}
      />
      <Container style={{ classes: "container position-relative" }}>
        <Header
          content={{
            title: props.data.contentfulFeatureCategory.title,
            text: props.data.contentfulFeatureCategory.description,
          }}
          style={{ color: "text-center pb-5" }}
        />
        <div className="desktop-only">
          <Grid style="grid-8">
            {props.data.allContentfulFeatureCategory.edges
              .sort((a, b) => a.node.order - b.node.order)
              .map(edge => {
                return (
                  <Link
                    to={"/" + edge.node.slug}
                    className="feature-category-filter"
                    activeClassName="feature-category-filter__active"
                  >
                    {edge.node.title}
                  </Link>
                )
              })}
          </Grid>
        </div>
      </Container>
      <Container style="my-5 pb-5">
        <div className="row">
          <div className="col-12 col-md-5">
            <div
              style={{
                background: "#f7f7f8",
                textAlign: "center",
                padding: "1rem",
                fontSize: "1rem",
              }}
            >
              <FeatureImg />
              <div
                className="feature-summary"
                data-sal="fade"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <MDXProvider>
                  <MDXRenderer>
                    {props.data.contentfulFeatureCategory.summary.childMdx.body}
                  </MDXRenderer>
                </MDXProvider>
              </div>
            </div>
          </div>
          {enlargedSrc && (
            <div className="overlay" onClick={handleClose}>
              <img
                src={enlargedSrc}
                alt="Enlarged"
                className="enlarged-image"
              />
            </div>
          )}
          <div className="col-12 col-md-7 testname">
            {props.data.contentfulFeatureCategory.feature
              .sort((a, b) => b.title.localeCompare(a.title))
              .map(feature => {
                return (
                  <CardFeature
                    content={{
                      title: feature.title,
                      slug: feature.slug,
                      summary: feature.description.childMdx.body,
                      image: feature.image,
                    }}
                  />
                )
              })}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default FeatureLayout
