import React from "react"
import { Link } from "gatsby"
import "./button.scss"
import Arrow from "../../../assets/images/arrow.svg"

const Button = ({ href, text, style, anim, delay, arrow, duration }) => {
  return (
    <Link
      to={href}
      className={`buttonOn ${style || ""}`}
      data-sal={anim || "fade"}
      data-sal-easing="ease"
      data-sal-duration={duration || "600"}
    >
      {text}
      {arrow === "true" && <img src={Arrow} alt="arrow" className="ml-1" />}
    </Link>
  )
}

export default Button
