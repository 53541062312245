import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/navbar"
import Header from "../components/header"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import FeaturesCardMd from "../components/featureCardMd"
import SubHeader from "../components/subHeader"
import Layout from "../components/layout"
import Head from "../components/head"
import flow1 from "../assets/images/flow1.svg"
import flow2 from "../assets/images/flow2.svg"
import flow3 from "../assets/images/flow3.svg"
import flow4 from "../assets/images/flow4.svg"
import screen1 from "../assets/images/pipeline-code-1.png"
import screen2 from "../assets/images/pipeline-code-2.png"
import screen3 from "../assets/images/pipeline-code-3.png"
import screen4 from "../assets/images/pipeline-code-4.png"

export const query = graphql`
  query {
    allContentfulFeature {
      edges {
        node {
          title
          slug
          icon
        }
      }
    }
  }
`

const Feature = props => {
  // const icon = require(`../assets/icons/features/${props.data.contentfulFeature.slug}.svg`)
  const image = require(`../assets/images/features/accounting.png`)
  const example = require(`../assets/images/example.svg`)

  return (
    <Container style={{ classes: "dark-blue overflow-hidden" }}>
      <Layout navbar="transparent">
        <Head
          title="API Pipeline"
          description="Find out how Onport API’s can streamline your marketplace operations"
        />
        <Navbar style={{ classes: "position-absolute dark-blue" }} />
        <BackgroundShape
          style={{
            shape: "clip-fixed-small",
            color: "gradient",
            particles: true,
          }}
        />
        <Container style={{ classes: "container" }}>
          <Header
            content={{
              title: "API Pipeline",
              text:
                "Find out how our customers use Onport to help increase their sales and grow their retail businesses",
            }}
            style={{ color: "text-center" }}
          />
        </Container>
        <Container style={{ classes: "container-fluid very-dark-blue" }}>
          <Container style={{ classes: "container py-5 position-relative" }}>
            <SubHeader
              content={{
                title: "",
                text:
                  "At Onport, we believe an API-first approach is crucial to fostering innovation and managing the ever-changing demands of the marketplace environment. Onport’s APIs are able to perform complex calculations and provide powerful performance for your marketplace. Our state-of-the-art technology and infrastructure, allow us to handle millions of API calls every day, with no performance issues, and are fully prepared to scale.</br></br>Onport is designed to work across all aspects of marketplace operations, managing complex processes from shipping splitting to commission rates calculations and custom order routing. Here are some examples of how our APIs work:",
              }}
              style={{ color: "my-5 py-5 text-white" }}
            />
            <img
              style={{
                position: "absolute",
                right: "-45%",
                top: "10%",
                height: "420px",
                borderRadius: "8px",
              }}
              className="desktop-only shadow"
              src="https://taact-brand-assets.netlify.app/assets/screenshots/inhouse-fullfilment.jpg"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
        <Container style={{ classes: "container-fluid dark-blue" }}>
          <Container style={{ classes: "container pb-5 position-relative" }}>
            <SubHeader
              content={{
                title: "Address Validation",
                text:
                  "Take, for instance, the concept of address validation. You want to incorporate a fully customized Address Validation Schema into your marketplace to reduce instances of incorrect data entry from customers and missed/lost deliveries.</br></br>Our API is capable of taking the address the customer has entered and validating the zip code length required as per the address provided and ensure that all other mandatory fields are filled - city, street name, apartment number, etc. Ensuring that all preconditions for delivery have been met before proceeding with the order.</br></br>Once finished, the API will return that the address provided is either valid or invalid. Reducing instances of error and saving valuable time for both company and customer.",
              }}
              style={{ color: "text-white" }}
            />
            <img
              style={{
                height: "160px",
                marginBottom: "6rem",
                borderRadius: "8px",
              }}
              className="desktop-only"
              src={flow1}
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
            <img
              style={{
                position: "absolute",
                right: "-60%",
                top: "20%",
                height: "500px",
                borderRadius: "8px",
              }}
              className="desktop-only shadow"
              src={screen1}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
        <Container style={{ classes: "container-fluid very-dark-blue" }}>
          <Container style={{ classes: "container pb-5 position-relative" }}>
            <SubHeader
              content={{
                title: "Custom Commission Rates",
                text:
                  "The Onport API will take into account, the relevant criteria such as: <ul><li>Order status</li><li>Days since the order date</li><li>Item value (that the customer paid)</li><li>Order value (item value + shipping + extras [round up, donation etc])</li><li>Shipping contribution</li></ul>From these variables, the API will then return with the appropriate rate for the relevant vendor. This is great if you have a busy marketplace with numerous vendors, each of who have their own preconditions regarding commissions like shipping contributions to different countries.",
              }}
              style={{ color: "text-white" }}
            />
            <img
              style={{
                height: "160px",
                marginBottom: "6rem",
                borderRadius: "8px",
              }}
              className="desktop-only"
              src={flow2}
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
            <img
              style={{
                position: "absolute",
                right: "-30%",
                top: "20%",
                height: "500px",
                borderRadius: "8px",
              }}
              className="desktop-only shadow"
              src={screen2}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
        <Container style={{ classes: "container-fluid dark-blue" }}>
          <Container style={{ classes: "container pb-5 position-relative" }}>
            <SubHeader
              content={{
                title: "Shipping Rate Selection",
                text:
                  "As your marketplace grows, so will your customer base. This means that you may have customers from around the world and therefore, sorting out your shipping rates with your vendors is crucial. Shipping can be complex and expensive but by using Onport, you can automate the entire process.</br></br> Onport gives you the option to pick the best available shipping rate. Using our API, we are able to analyze the SKU, the vendor involved, product information such as weight, size as well as destination, and combine these parameters to generate the best available rate for that order.</br></br> This allows you to monitor your own shipping costs as well as those of your vendors, paying the correct commission as required.",
              }}
              style={{ color: "text-white" }}
            />
            <img
              style={{
                height: "160px",
                marginBottom: "6rem",
                borderRadius: "8px",
              }}
              className="desktop-only"
              src={flow3}
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
            <img
              style={{
                position: "absolute",
                right: "-40%",
                top: "20%",
                height: "500px",
                borderRadius: "8px",
              }}
              className="desktop-only shadow"
              src={screen3}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
        <Container style={{ classes: "container-fluid very-dark-blue" }}>
          <Container style={{ classes: "container pb-5 position-relative" }}>
            <SubHeader
              content={{
                title: "Shipment Splitting",
                text:
                  "By increasing the number of vendors on your site, you can increase the number of orders to be sorted. For instance, one customer may make one purchase order, regarding products from multiple vendors on your site. This seems complicated but with Onport, it can be a simple process.</br></br> Onport is able to segregate the different items involved in the purchase by looking at SKUs and the vendors involved.</br></br> This allows you to segment one single purchase order into multiple shipments and notify the relevant vendors involved.",
              }}
              style={{ color: "text-white" }}
            />
            <img
              style={{
                height: "160px",
                marginBottom: "6rem",
                borderRadius: "8px",
              }}
              className="desktop-only"
              src={flow4}
              data-sal="fade"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
            <img
              style={{
                position: "absolute",
                right: "-57%",
                top: "20%",
                height: "500px",
                borderRadius: "8px",
              }}
              className="desktop-only shadow"
              src={screen4}
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="600"
            />
          </Container>
        </Container>
        {/* <Container style={{ classes: "container mt-5 pt-5" }}>
			<div className="features-grid features-grid__center features-grid__4-col mt-5">
			{props.data.allContentfulFeature.edges.slice(0, 7).map(edge => {
				return (
				<FeaturesCardMd
					content={{
					slug: edge.node.slug,
					title: edge.node.title,
					icon: edge.node.icon,
					}}
					style={{ color: "" }}
				/>
				)
			})}
			<FeaturesCardMd
				content={{
				slug: "/",
				title: "Discover all the features",
				icon: "all",
				}}
				style={{ color: "feature-card-md__reverse" }}
			/>
			</div>
		</Container> */}
      </Layout>
    </Container>
  )
}

export default Feature
