import React from "react"
import { Link } from "gatsby"

const Button = props => {
  return (
    <Link
      to={props.style.href}
      className={props.style.shape}
      activeClassName={props.style.active}
      data-sal="fade"
      data-sal-easing="ease"
      data-sal-duration="600"
    >
      {props.style.text}
    </Link>
  )
}

export default Button
