import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./useCaseHeader.scss"
import Arrow from "../../../assets/images/arrow.svg"

const useCaseHeader = ({ title, subtitle, image }) => {
  return (
    <div className="use-case-header">
      <div className="image" style={{ backgroundImage: `url(${image})` }}></div>
      <h1
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="600"
      >
        {title}
      </h1>
      <h2
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="600"
      >
        {subtitle}
      </h2>
      <a
        data-sal="slide-up"
        data-sal-delay="700"
        data-sal-easing="ease"
        data-sal-duration="600"
        href="#form"
      >
        <p>Get in touch</p>
        <img src={Arrow} alt="arrow" />
      </a>
    </div>
  )
}

export default useCaseHeader
