import React from "react"
import BackgroundShape from "../components/backgroundShape"
import Navbar from "../components/navbar"
import Header from "../components/header"
import Container from "../components/container"
import Head from "../components/head"
import Layout from "../components/layout"
import img from "../assets/icons/illustration.svg"
import Button from "../components/button"
import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import { MDXProvider } from "@mdx-js/react"
import SubHeader from "../components/subHeader"
import Footer from "../components/footer"
import FeaturesCardMd from "../components//featureCardMd"
import LandingCard from "../components//landingCard"
import { graphql, useStaticQuery } from "gatsby"

export const query = graphql`
  query($slug: String!) {
    contentfulPlatform(slug: { eq: $slug }) {
      title
      metaDescription {
        metaDescription
      }
      summary
      slug
      setupTime
      orderSync
      inventorySync
      updated
      benefits {
        benefits
        childMdx {
          body
        }
      }
      integrationCategory {
        title
        slug
      }
      beta
      task {
        slug
      }
    }
    allContentfulFeature {
      edges {
        node {
          title
          slug
          icon
        }
      }
    }
  }
`

const Partner = props => {
  const setupTime = props.data.contentfulPlatform.setupTime
  const orderSync = props.data.contentfulPlatform.orderSync
  const inventorySync = props.data.contentfulPlatform.inventorySync
  const task = props.data.contentfulPlatform.task
    ? props.data.contentfulPlatform.task
    : ""
  let secondDelay = 0
  // const description = props.data.contentfulPlatform.benefits.childMdx ? props.data.contentfulPlatform.benefits.benefits.childMdx.body : props.data.contentfulPlatform.summary
  if (!props.data.contentfulPlatform.updated) {
    return (
      <Layout navbar="transparent">
        <Head
          title={"" + props.data.contentfulPlatform.title}
          description={
            props.data.contentfulPlatform.metaDescription
              ? props.data.contentfulPlatform.metaDescription.metaDescription
              : "Dropshipping and multi vendor marketplace automation for Shopify, Magento and WooCommerce. Sync orders, inventory, shipping & payments."
          }
        />
        <BackgroundShape
          style={{
            shape: "clip-fixed",
            color: "bg-partners-sm",
            particles: false,
          }}
        />
        <Container style={{ classes: "container" }}>
          <Header
            content={{ title: props.data.contentfulPlatform.title, text: "" }}
            style={{ color: "text-center" }}
          />
          <img
            src={require(`../assets/icons/platform/round/${props.data.contentfulPlatform.slug}.svg`)}
            alt={props.data.contentfulPlatform.title}
            className="test-int"
          />
          <div className="integrations-opt-grid">
            {setupTime ? (
              <div>
                <p className="integrations-opt-grid__headers">Setup time</p>
                <p className="integrations-opt-grid__text">
                  {props.data.contentfulPlatform.setupTime}
                </p>
              </div>
            ) : (
              ""
            )}
            {orderSync ? (
              <div>
                <p className="integrations-opt-grid__headers">Order Sync</p>
                <p className="integrations-opt-grid__text">
                  {props.data.contentfulPlatform.orderSync}
                </p>
              </div>
            ) : (
              ""
            )}
            {inventorySync ? (
              <div>
                <p className="integrations-opt-grid__headers">Inventory Sync</p>
                <p className="integrations-opt-grid__text">
                  {props.data.contentfulPlatform.inventorySync}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex justify-content-around align-items-start mt-5">
            <Container
              style={{
                classes: "d-flex align-items-center justify-content-between",
              }}
            >
              <div className="integration-description">
                <p>
                  {props.data.contentfulPlatform.benefits ? (
                    <MDXProvider>
                      <MDXRenderer>
                        {props.data.contentfulPlatform.benefits.childMdx.body}
                      </MDXRenderer>
                    </MDXProvider>
                  ) : (
                    props.data.contentfulPlatform.summary
                  )}
                </p>
                {task ? (
                  <a
                    href={`https://support.jetti.io/jetti/en/vendor-onboarding/${props.data.contentfulPlatform.task.slug}`}
                    target="_blank"
                    className="btn-square btn-square__full mb-5"
                  >
                    Setup guide
                  </a>
                ) : (
                  ""
                )}
                <Button
                  style={{
                    href: `/partners/${props.data.contentfulPlatform.integrationCategory.slug}`,
                    text: "Go back",
                    shape: "btn-square btn-square__border",
                  }}
                />
              </div>
              <img
                src={img}
                className="illustration my-5 desktop-only"
                alt="integrtion-category-illustration"
              />
            </Container>
          </div>
        </Container>
      </Layout>
    )
  } else {
    return (
      <Layout navbar="transparent">
        <Head
          title="Home"
          description="Dropshipping automation for Shopify, Magento and WooCommerce. Sync orders, inventory, shipping & payments."
        />

        <BackgroundShape
          style={{
            shape: "clip-fixed-small",
            color: "bg-partners-md",
            particles: false,
          }}
        />
        <Container style={{ classes: "container position-relative" }}>
          <Header
            content={{
              title: props.data.contentfulPlatform.title,
              text: props.data.contentfulPlatform.summary,
            }}
            style={{ color: "text-center" }}
          />
          <Button
            style={{
              href: `https://onport.com/contacts/book-demo/`,
              text: "Get started",
              shape: "btn-contact btn-contact__light",
              active: "",
            }}
          />
        </Container>
        <Container style={{ classes: "container text-center my-5" }}>
          <div className="feature-highlight__description mb-4 py-5 text-left row justify-content-center align-items-center">
            <div className="col-12 col-md-7 blog-post p-0">
              <MDXProvider>
                <MDXRenderer>
                  {props.data.contentfulPlatform.benefits.childMdx.body}
                </MDXRenderer>
              </MDXProvider>
              {task ? (
                <a
                  href={`https://support.jetti.io/jetti/en/vendor-onboarding/${props.data.contentfulPlatform.task.slug}`}
                  target="_blank"
                  className="btn-square btn-square__full mt-3 d-inline-block"
                >
                  Setup guide
                </a>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <img
                style={{ height: "300px", width: "250px", zIndex: "1" }}
                src={require(`../assets/icons/platform/round/${props.data.contentfulPlatform.slug}.svg`)}
                alt=""
              />
              <img
                style={{ height: "300px", width: "250px", marginLeft: "-10%" }}
                src={require(`../assets/icons/platform/round/onport.svg`)}
                alt=""
              />
            </div>
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Partner
