import React from "react"
import Container from "../../components/container"
import Head from "../../components/head"
import { getNames } from "country-list"
import NodeGarden from "../../components/new/nodeGarden/nodeGarden"
import Grid from "../../components/new/grid/grid"
import Layout from "../../components/new/layout/layout"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../../components/new/paragraph/paragraph"
import SubHeader from "../../components/new/subHeader/subHeader"
import HubspotContactFormPartner from "../../components/hubspotContactFormPartner"

export default function Sellers() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomer(filter: { featured: { eq: true } }, limit: 4) {
        edges {
          node {
            logo
            title
            featured
            url
          }
        }
      }
    }
  `)
  const countries = getNames()
  return (
    <Layout navbar="darkerblue">
      <Head
        title="Transform Your Marketplace Operations Now"
        description="Ensure the future of your marketplace by booking a demo of the Onport Platform now."
      />
      <Container style={{ classes: "container-fluid bg-contact-lg py-5" }}>
        <Container style={{ classes: "container position-relative" }}>
          <div className="row mt-5 pt-5 align-items-start">
            <div className="col-12 col-md-6">
              <img
                src={"/images/onport-unite-logo.svg"}
                style={{ height: "60px" }}
                className="mb-4 mt-3"
              />
              <SubHeader
                title="Increase your revenue with product catalog growth through Onport Unite"
                style="light"
              />
              <Paragraph
                text="Expand product offering "
                style="light w-75 mt-5 xxl"
              />
              <Paragraph
                text="Connect with brands who share the same target audience to offer your customers a wider choice of products from a curated network of vendors."
                style="light w-75 mt-3"
              />
              <Paragraph
                text="Grow your business"
                style="light w-75 mt-5 xxl"
              />
              <Paragraph
                text="Experiment with new product categories to attract more customers as well as leveraging your current customer base."
                style="light w-75 mt-3"
              />
              <Paragraph
                text="Ensure a seamless onboarding"
                style="light w-75 mt-5 xxl"
              />
              <Paragraph
                text="Because these selected vendors are interested in onboarding into the Onport ecosystem, your marketplace operations are simplified and more efficient"
                style="light w-75 mt-3"
              />
            </div>
            <div className="col-12 col-md-6 bg-lol2 pt-4">
              <h2 className="text-white pl-4" style={{ fontWeight: "bold" }}>
                Take the first step today
              </h2>
              <HubspotContactFormPartner id="bb808a89-4fcf-4774-9869-7b5d07438cb6" />
            </div>
          </div>
          <Grid style="grid-4 mt-5 py-4">
            {data.allContentfulCustomer.edges.map(edge => {
              if (edge.node.featured) {
                return (
                  <a href={edge.node.url} target="_blank">
                    <img
                      src={edge.node.logo}
                      alt={edge.node.title}
                      style={{
                        opacity: "0.6",
                        width: "160px",
                        filter: "invert(100%) saturate(0)",
                        margin: "1rem 0",
                      }}
                    />
                  </a>
                )
              }
            })}
          </Grid>
        </Container>
      </Container>
    </Layout>
  )
}
