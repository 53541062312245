import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"

export default function Terms() {
  return (
    <Layout navbar="transparent">
      <Head
        title="Terms and conditions of the Onport Platform"
        description="The terms and conditions for using the Onport Platform"
      />

      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "bg-terms-sm",
          particles: false,
        }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{ title: "Onport terms and conditions", text: "" }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container terms mb-5" }}>
        <p>
          Please read these terms and conditions (“terms”) carefully before
          using the services offered by JBUX, ltd. (“Onport”). by submitting the
          account creation, which references these terms and is accepted by
          Onport or by agreeing to have an account created for you by Onport,
          you or the entity that you represent (“Client”) are unconditionally
          consenting to be bound by and are becoming a party to the services
          agreement consisting of these terms (the “agreement”) with respect to
          the services being provided by Onport. If a separate signed written
          agreement with respect to the services exists between Client and
          Onport, the terms of that signed written agreement (excluding the
          pre-printed terms of any purchase order, confirmation or similar
          document, which will have no effect and will not be considered agreed
          to by Oonport) shall take precedence over this agreement, and you
          acknowledge that Cclient is bound by the terms of that signed written
          services agreement. In cases where a direct relationship exists
          between Onport and Client’s vendors, as pre-agreed by the Client and
          Onport (“Vendors”), such Vendors are also bound by the relevant
          provisions of these Terms. The provision of the services is
          conditioned on, and Client’s installation or use of the services shall
          constitute, Client’s assent to the terms of this agreement or of such
          existing separate written agreement to the exclusion of all other
          terms. If you do not unconditionally agree to all of the terms of this
          agreement, do not create an account or give your permission to have an
          account created for you, and you will have no right to use the
          Services. If these terms are considered an offer, acceptance is
          expressly limited to these terms to the exclusion of all other terms.
          By using the Services, Client/Vendor is entering in a monthly
          Subscription that will renew on a monthly basis unless terminated by
          either party in accordance with the terms herein.
        </p>
        <h2>Access to the Service</h2>
        <p>
          Subject to Client’s/Vendor’s compliance with the terms and conditions
          of this Agreement, Onport grants Client/Vendor the right and license
          to access and use the services according to to a plan that fits the
          Client’s/Vendor’s needs (“Subscribed Plan”) (collectively, the
          “Service,” or “Services”) for the internal business purposes of
          Client/Vendor, only as provided herein and only in accordance with
          Onport’ applicable official user documentation (the “Documentation”).
          Where pre-approved by the Client, the right and license to use certain
          Services may extend to Client’s vendors with a direct relationship
          with Onport, under the same terms of this Agreement. The Subscribed
          Plan may have certain limitations or restrictions on the Client’s use
          of the Services (including, without limitation, the number of orders
          over a designated timeframe, integrated channels, or other services
          and features) (“Limits”), and it is Client’s/Vendor’s sole
          responsibility to monitor its usage in order to comply with such
          Limits.
        </p>
        <h2>Onboarding Assistance</h2>
        <p>
          Upon payment of any applicable fees, Onport agrees to use reasonable
          commercial efforts to provide standard implementation assistance for
          the Service (“Onboarding Assistance”). If Onport provides Onboarding
          Assistance beyond the agreed-upon hours estimate, or if additional
          services are provided outside of those specified in Commercial
          conversations between Onport and the Client the Client will be billed
          at Onport's then-current hourly rates or in accordance with the
          applicable pricing at the time of service. In cases where pre-approved
          by the Client, Onboarding Assistance may extend to vendors with a
          direct, Onport-approved relationship, provided such assistance
          complies with all terms of this Agreement.
        </p>
        <h2>Support; Service Levels</h2>
        <p>
          Onport will use reasonable efforts to make the Service available 24
          hours a day, 7 days a week, except for emergency maintenance, planned
          down-time for maintenance, if ordered by a law enforcement or
          government agency or if Onport believes, in its sole discretion, (a)
          an interruption may be required for security or emergency-related
          purposes, or by applicable law or (b) that Client’s/Vendor’s use of
          the Service poses an unreasonable risk to Onport or a Onport Client.
        </p>
        <h2>Service Updates</h2>
        <p>
          From time to time, Onport may provide upgrades, patches, enhancements,
          or fixes for the Services to its Clients/Vendors generally without
          additional charge (“Updates”), and such Updates will become part of
          the Services and subject to this Agreement; provided that Onport shall
          have no obligation under this Agreement or otherwise to provide any
          such Updates. Client/Vendor understands that Onport may cease
          supporting old versions or releases of the Services at any time in its
          sole discretion; provided that Onport shall use commercially
          reasonable efforts to give Client/Vendor thirty (30) days prior notice
          of any major changes. For clarity, Onport may also offer new features
          for an additional fee, and Client/Vendor may subscribe to such new
          features by agreeing in writing with Onport. These new features and
          Updates will be extended to Client’s vendors with a direct
          Onport-approved relationship, under the same terms and conditions.
        </p>
        <h2>Ownership; Feedback</h2>
        <p>
          As between the parties, Onport retains all right, title, and interest
          in and to the Services, and all software, products, works, and other
          intellectual property and moral rights related thereto or created,
          used, or provided by Onport for the purposes of this Agreement,
          including any copies and derivative works of the foregoing. No rights
          or licenses are granted except as expressly and unambiguously set
          forth in this Agreement. Client and Client’s vendors with a direct
          Onport-approved relationship may from time to time provide
          suggestions, comments or other feedback to Onport with respect to the
          Service (“Feedback”). Feedback, even if designated as confidential by
          Client and Client’s vendors with a direct Onport-approved
          relationship, shall not create any confidentiality obligation for
          Onport notwithstanding anything else. Client and Vendors shall, and
          hereby does, grant to Onport a nonexclusive, worldwide, perpetual,
          irrevocable, transferable, sublicensable, royalty-free, fully paid up
          license to use and exploit the Feedback for any purpose. Nothing in
          this Agreement will impair Onport’ right to develop, acquire, license,
          market, promote or distribute products, software or technologies that
          perform the same or similar functions as, or otherwise compete with
          any products, software or technologies that Client and Vendors may
          develop, produce, market, or distribute.
        </p>
        <h2>Fees; Payment</h2>
        <p>
          Client and Vendor shall pay Onport fees for the Service as agreed
          between the Client/Vendor and Onport (“Fees”). Unless otherwise
          agreed, all Fees shall be invoiced monthly in advance, and all
          invoices issued under this Agreement are payable in U.S. dollars,
          automatically, on the same date of the invoice. The Client/Vendor
          shall pay interest on the overdue sum from and including the 10th
          calendar day following the due date until payment of the overdue sum,
          whether before or after judgment. Interest under this clause will
          accrue each day at 4% a year above the Bank of England’s base rate
          from time to time, but at 4% a year for any period when that base rate
          is below 0%.Client/Vendor shall be responsible for all taxes
          associated with the Services (excluding taxes based on Onport’ net
          income). All Fees paid are non-refundable and are not subject to
          set-off. If Client/Vendor chooses to upgrade or downgrade the level of
          Service, such changes must be agreed upon by Onport and the
          Client/Vendor, and different fees and features may apply.{" "}
        </p>
        <p>
          The Client/Vendor acknowledges that Fees will be subject to routine
          review by the Onport team every three (3) months. This review will be
          based on pre-defined limits and aligned with Onport’s pricing
          structure. Based on this review, Onport may, at its discretion,
          increase the Fees in accordance with the applicable pricing plan and
          tier, reflecting the Client's/Vendor’s actual usage and requirements,
          provided that, Onport provides the Client/Vendor with notice of such
          increase at least fifteen (15) days prior to charge of the following
          Fee.
        </p>
        <p>
          If the Client/Vendor makes payment by credit card, Client/Vendors
          hereby authorizes Onport to charge Client’s/Vendor’s credit card at
          the expiration of Client’s/Vendor’s free trial period, if any, and on
          Client’s/Vendor’s invoice date until this Agreement is terminated. For
          clarity, an upgrade from any free version of the Service to a paying
          version will end the Client’s/Vendor’s free trial period, and
          Client/Vendor will be billed for its account immediately upon such
          upgrade.
        </p>
        <h2>Restrictions</h2>
        <p>
          Except as expressly set forth in this Agreement, Client/Vendor shall
          not (and shall not permit any third party to), directly or indirectly:{" "}
        </p>
        <p>
          (i) reverse engineer, decompile, disassemble, or otherwise attempt to
          discover the source code, object code, or underlying structure, ideas,
          or algorithms of the Service (except to the extent applicable laws
          specifically prohibit such restriction);{" "}
        </p>
        <p>
          (ii) modify, translate, or create derivative works based on the
          Service;{" "}
        </p>
        <p>
          (iii) copy, rent, lease, distribute, pledge, assign, or otherwise
          transfer or encumber rights to the Service;{" "}
        </p>
        <p>
          (iv) remove or otherwise alter any proprietary notices or labels from
          the Service or any portion thereof;{" "}
        </p>
        <p>(v) use the Services for timesharing or service bureau purposes; </p>
        <p>
          (vi) use the Service to build an application or product that is
          competitive with any Onport product or service;{" "}
        </p>
        <p>
          (vii) interfere or attempt to interfere with the proper working of the
          Service or any activities conducted on the Service; or{" "}
        </p>
        <p>
          (viii) bypass any measures Onport may use to prevent or restrict
          access to the Service (or other accounts, computer systems or networks
          connected to the Service).{" "}
        </p>
        <p>
          Client/Vendor is responsible for all of Client’s/Vendor’s activity in
          connection with the Service, including but not limited to uploading
          Client Data (as defined below) onto the Service.{" "}
        </p>
        <p>Client /Vendor </p>
        <p>
          (a) shall use the Service in compliance with all applicable local,
          state, national and foreign laws, treaties and regulations in
          connection with Client’s/Vendor’s use of the Service (including those
          related to data privacy, international communications, export laws and
          the transmission of technical or personal data laws), and{" "}
        </p>
        <p>
          (b) shall not use the Service in a manner that violates any
          third-party intellectual property, contractual or other proprietary
          rights.
        </p>
        <p>
          Client/Vendor acknowledges and agrees that the Service may operate on
          or with or use services operated or provided by third parties (“Third
          Party Services”). Onport is not responsible for the operation of any
          Third Party Services nor the availability or operation of the Service
          to the extent such availability and operation is dependent upon Third
          Party Services. Onport does not make any representations or warranties
          with respect to Third Party Services or any third-party providers. Any
          exchange of data or other interaction between Client/Vendor and a
          third party provider is solely between Client/Vendor and such third
          party provider and is governed by such third party’s terms and
          conditions. Third-party provider is not responsible for providing
          support services in connection with the Service.
        </p>
        <h2>Data Privacy</h2>
        <p>
          If Client/Vendor Data contains Personally Identifiable Information,
          its collection, storage, handling and processing shall be governed by
          Onport's Data Processing Agreement (“DPA”), which is available at:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer nofollow"
            href="https://onport.com/data-processing-agreement/"
          >
            https://onport.com/data-processing-agreement/
          </a>
        </p>
        <h2>Confidentiality; Client Data; Publicity</h2>
        <p>
          Each party (the “Receiving Party”) understands that the other party
          (the “Disclosing Party”) has disclosed or may disclose information
          relating to the Disclosing Party’s technology or business (hereinafter
          referred to as “Proprietary Information” of the Disclosing Party).
        </p>
        <p>The Receiving Party agrees: </p>
        <p>
          (i) not to divulge to any third person any such Proprietary
          Information,{" "}
        </p>
        <p>
          (ii) to give access to such Proprietary information solely to those
          employees with a need to have access thereto for purposes of this
          Agreement, and{" "}
        </p>
        <p>
          (iii) to take the same security precautions to protect against
          disclosure or unauthorized use of such Proprietary Information that
          the party takes with its own proprietary information, but in no event
          will a party apply less than reasonable precautions to protect such
          Proprietary Information.{" "}
        </p>
        <p>
          The Disclosing Party agrees that the foregoing will not apply with
          respect to any information that the Receiving Party can document{" "}
        </p>
        <p>
          (a) is or becomes generally available to the public without any action
          by, or involvement of, the Receiving Party, or{" "}
        </p>
        <p>
          (b) was in its possession or known by it prior to receipt from the
          Disclosing Party, or{" "}
        </p>
        <p>
          (c) was rightfully disclosed to it without restriction by a third
          party, or{" "}
        </p>
        <p>
          (d) was independently developed without use of any Proprietary
          Information of the Disclosing Party. Nothing in this Agreement will
          prevent the Receiving Party from disclosing the Proprietary
          Information pursuant to any judicial or governmental order, provided
          that the Receiving Party gives the Disclosing Party reasonable prior
          notice of such disclosure to contest such order.
        </p>
        <p>
          For purposes of this Agreement, “Client/Vendor Data” shall mean any
          data, information or other material provided, uploaded, or submitted
          by Client/Vendor to the Service in the course of using the Service.
          Client/Vendor shall retain all right, title and interest in and to the
          Client/Vendor Data, including all intellectual property rights
          therein. Client/Vendor, not Onport, shall have sole responsibility for
          the accuracy, quality, integrity, legality, reliability,
          appropriateness, and intellectual property ownership or right to use
          of all Client/Vendor Data.{" "}
        </p>
        <p>
          Onport shall use commercially reasonable efforts to maintain the
          security and integrity of the Service and the Client/Vendor Data.
          Onport is not responsible to Client/Vendor for unauthorized access to
          Client/Vendor Data or the unauthorized use of the Service unless such
          access is due to Onport’ gross negligence or willful misconduct.
          Client/Vendor is responsible for the use of the Service by any person
          to whom Client/Vendor has given access to the Service, even if
          Client/Vendor did not authorize such use.{" "}
        </p>
        <p>
          Client/Vendor agrees and acknowledges that Client/Vendor Data may be
          irretrievably deleted if Client’s/Vendor’s account is ninety (90) days
          or more delinquent. Notwithstanding anything to the contrary,
          Client/Vendor acknowledges and agrees that Onport may{" "}
        </p>
        <p>
          (i) internally use and modify (but not disclose) Client/Vendor Data
          for the purposes of{" "}
        </p>
        <p>
          (A) providing the Services and any support or consultation services to
          Client/Vendor and{" "}
        </p>
        <p>
          (B) generating Aggregated De-Identified Data (as defined below), and{" "}
        </p>
        <p>
          (ii) freely use and make available Aggregated De-Identified Data for
          Onport’ business purposes (including, without limitation, for purposes
          of improving, testing, operating, promoting and marketing Onport’
          products and services). “Aggregated De-Identified Data” means data
          submitted to, collected by, or generated by Onport in connection with
          Client’s/Vendor’s use of the Service, but only in aggregate,
          anonymized form which can in no way be linked specifically to
          Client/Vendor or any individual. Notwithstanding anything else, Onport
          is permitted to disclose (including through display of Client’s logo)
          that Client/Vendor is one of its customers (including in its publicity
          and marketing materials), provided that Client/Vendor may revoke the
          foregoing rights upon prior written notice to Onport.
        </p>
        <h2>Term; Termination</h2>
        <p>
          This Agreement shall commence upon the effective date of the account
          creation, and, unless earlier terminated in accordance herewith, shall
          automatically renew for additional successive monthly periods unless
          either party notifies the other party of such party’s intention not to
          renew no later than thirty (30) days prior to the next renewal date.
          In the event of a material breach of this Agreement by either party,
          the non-breaching party may terminate this Agreement by providing
          written notice to the breaching party, provided that the breaching
          party does not materially cure such breach within thirty (30) days of
          receipt of such notice. Without limiting the foregoing, Onport may
          suspend or limit Client’s/Vendor’s access to or use of the Service if
          (i) Client’s/Vendor’s account is more than thirty (30) days past due,
          (ii) Onport determines, in its sole discretion, that Client’s/Vendor’s
          business plans are competitive with those of Onport, or (iii)
          Client’s/Vendor’s use of the Service results in (or is reasonably
          likely to result in) damage to or material degradation of the Service
          which interferes with Onport’ ability to provide access to the Service
          to other customers; Provided that in the case of subsection (iii): (a)
          Onport shall use reasonable good faith efforts to work with
          Client/Vendor to resolve or mitigate the damage or degradation in
          order to resolve the issue without resorting to suspension or
          limitation; (b) prior to any such suspension or limitation, Onport
          shall use commercially reasonable efforts to provide notice to
          Client/Vendor describing the nature of the damage or degradation; and
          (c) Onport shall reinstate Client’s/Vendor’s use of or access to the
          Service, as applicable, if Client/Vendor remediates the issue within
          thirty (30) days of receipt of such notice. Client/Vendor agrees and
          acknowledges that Client/Vendor Data may be irretrievably deleted upon
          termination of this Agreement. All provisions of this Agreement which
          by their nature should survive termination shall survive termination,
          including, without limitation, accrued payment obligations, ownership
          provisions, warranty disclaimers, indemnity and limitations of
          liability.
        </p>
        <h2>Indntification</h2>
        <p>
          Client/Vendor shall defend, indemnify, and hold harmless Onport, its
          affiliates and each of its and its affiliates’ employees, contractors,
          directors, suppliers, partners, third-party service providers and
          representatives from all liabilities, claims, and expenses (“Losses”),
          that arise from or relate to the Client/Vendor Data or
          Client’s/Vendor’s use of the Service.
        </p>
        <h2>Disclaimer</h2>
        <p>
          Except for any express warranties set forth herein, the service is
          provided “as is” and “as available” and is without warranty of any
          kind, express or implied, including, but not limited to, the implied
          warranties of title, non-infringement, merchantability and fitness for
          a particular purpose, and any warranties implied by any course of
          performance, usage of trade, or course of dealing, all of which are
          expressly disclaimed.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          In no event shall Onport, nor its directors, employees, agents,
          partners, third-party service providers, suppliers or content
          providers, be liable under contract, tort, strict liability,
          negligence or any other legal or equitable theory with respect to the
          subject matter of this agreement (i) for any lost profits, data loss,
          cost of procurement of substitute goods or services, or special,
          indirect, incidental, punitive, or consequential damages of any kind
          whatsoever, (ii) for any bugs, viruses, trojan horses, or the like
          (regardless of the source of origination), or (iii) for any direct
          damages in excess of (in the aggregate) the fees paid (or payable) by
          Client to Onport hereunder in the twelve (12) months prior to the
          event giving rise to a claim hereunder.
        </p>
        <h2>Miscellaneous</h2>
        <p>
          This Agreement represents the entire agreement between Client/Vendor
          and Onport with respect to the subject matter hereof, and supersedes
          all prior or contemporaneous communications and proposals (whether
          oral, written or electronic) between Client/Vendor and Onport with
          respect thereto. The Agreement shall be governed by and construed in
          accordance with the law of England and Wales. Each party irrevocably
          agrees that the courts of England and Wales shall have exclusive
          jurisdiction to settle any dispute or claim (including non-contractual
          disputes or claims) arising out of or in connection with this
          Agreement or its subject matter or formation.
        </p>
        <p>
          All notices under this Agreement shall be in writing and shall be
          deemed to have been duly given when received, if personally delivered
          or sent by certified or registered mail, return receipt requested;
          when receipt is electronically confirmed, if transmitted by facsimile
          or e-mail; or the day after it is sent, if sent for next day delivery
          by recognized overnight delivery service. Notices must be sent to the
          contacts for each party agreed during commercial conversations. Either
          party may update its address set forth above by giving notice in
          accordance with this section. Except for payment obligations, neither
          party shall be liable for any failure to perform its obligations
          hereunder where such failure results from any cause beyond such
          party’s reasonable control, including, without limitation, the
          elements; fire; flood; severe weather; earthquake; vandalism;
          accidents; sabotage; power failure; denial of service attacks or
          similar attacks; Internet failure; acts of God and the public enemy;
          acts of war; acts of terrorism; riots; civil or public disturbances;
          strikes, lock-outs or labor disruptions; any laws, orders, rules,
          regulations, acts or restraints of any government or governmental body
          or authority, civil or military, including the orders and judgments of
          courts.{" "}
        </p>
        <p>
          Neither party may assign any of its rights or obligations hereunder
          without the other party’s consent; provided that(i) either party may
          assign all of its rights and obligations hereunder without such
          consent to a successor-in-interest in connection with a sale of
          substantially all of such party’s business relating to this Agreement,
          and (ii) Onport may utilize subcontractors in the performance of its
          obligations hereunder. No agency, partnership, joint venture, or
          employment relationship is created as a result of this Agreement and
          neither party has any authority of any kind to bind the other in any
          respect. In any action or proceeding to enforce rights under this
          Agreement, the prevailing party shall be entitled to recover costs and
          attorneys’ fees. If any provision of this Agreement is held to be
          unenforceable for any reason, such provision shall be reformed only to
          the extent necessary to make it enforceable. The failure of either
          party to act with respect to a breach of this Agreement by the other
          party shall not constitute a waiver and shall not limit such party’s
          rights with respect to such breach or any subsequent breaches.
        </p>
        <p>
          <strong>Effective Date: November 13th, 2024</strong>
        </p>
      </Container>
    </Layout>
  )
}
