import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import SubHeader from "../components/subHeader"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"
import TeamMemberCard from "../components/teamMemberCard"
import CaseStudies from "../components/pages/caseStudies"
import CountUp from "react-countup"
import { useStaticQuery, graphql } from "gatsby"

export default function About() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCustomer {
        edges {
          node {
            logo
            title
            featured
            url
          }
        }
      }
      allContentfulTeamMember {
        edges {
          node {
            name
            role
            photo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  let delay = 0

  return (
    <Layout navbar="transparent">
      <Head
        title="Discover more about Onport"
        description="Learn more about Onport, a member of the FARFETCH Group."
      />
      <BackgroundShape
        style={{ shape: "clip-fixed", color: "bg-about-sm", particles: false }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{
              title: "What is Onport?",
              text:
                "Onport is a new way to fully automate inventory and shipping workflows, we provide seamless integrations with some of the world’s most popular e-commerce and accounting platforms.",
            }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>

      <Container style={{ classes: "container pt-5" }}>
        <div className="features-grid features-grid__center features-grid__2-col py-5 my-5">
          <div
            className="p-0 m-0"
            data-sal="slide-right"
            data-sal-easing="ease"
            data-sal-duration="600"
          >
            <img
              src="https://images.unsplash.com/photo-1513530534585-c7b1394c6d51?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2851&q=80"
              className=""
              style={{ width: "100%", objectFit: "cover" }}
              alt="about"
            />
          </div>

          <div
            className=""
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="600"
          >
            <p
              style={{
                fontSize: "1.8rem",
                fontWeight: "300",
                letterSpacing: "1px",
              }}
            >
              Our mission
            </p>
            <p style={{ fontSize: "1rem", fontWeight: "300" }}>
              We know how important it is for retailers to be efficient. Which
              is why we created one single, automated system which can manage a
              retailer’s inventory and operations – all from one platform. Our
              cloud-based technology can benefit businesses of any size – from
              start-ups right through to small enterprises. We strongly believe
              that technology should do the work for you. So, whether you’re
              currently fulfilling orders in-house, via dropship vendors or 3PL,
              Onport has the automated system that can grow your business while
              you sleep.
            </p>
          </div>
        </div>
      </Container>

      <Container style={{ classes: "container-fluid container-about py-5" }}>
        <Container
          style={{
            classes:
              "container features-grid features-grid__center features-grid__2-col text-light py-5 my-5",
          }}
        >
          <div
            className="text py-5"
            data-sal="slide-left"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <h2
              style={{
                fontSize: "5rem",
                fontFamily: "source code pro",
                fontWeight: "bold",
              }}
            >
              <CountUp end={200} delay={1} duration={6} />+
            </h2>
            <p>Customers</p>
          </div>
          <div
            className="text py-5"
            data-sal="slide-right"
            data-sal-easing="ease"
            data-sal-duration="300"
          >
            <h2
              style={{
                fontSize: "5rem",
                fontFamily: "source code pro",
                fontWeight: "bold",
              }}
            >
              <CountUp end={12000} delay={1} duration={6} />+
            </h2>
            <p>Vendors</p>
          </div>
        </Container>
      </Container>

      <Container style={{ classes: "container" }}>
        <SubHeader
          content={{
            title: "Our customers",
            text:
              "With our awesome diverse customer base, we're able to blend traditional in-house fulfillment with dropship and 3PL. Our customers range from start-ups, through to $50m+ Enterprises.",
          }}
          style={{ color: "" }}
        />
        <div className="features-grid features-grid__center features-grid__3-col">
          {data.allContentfulCustomer.edges.map(edge => {
            if (edge.node.featured) {
              delay += 100
              return (
                <a href={edge.node.url} target="_blank">
                  <img
                    src={edge.node.logo}
                    className="about-logo about-logo__home-alt"
                    alt={edge.node.title}
                    data-sal="slide-up"
                    data-sal-delay={delay}
                    data-sal-easing="ease"
                    data-sal-duration="600"
                  />
                </a>
              )
            }
          })}
        </div>
      </Container>

      {/* <Container style={{classes: "container" }}>
            <SubHeader content={{title: "Meet the team", text: "Whether you’re a startup, small business, or global enterprise, our teams across all timezones are here to support you. Businesses operating at scale can also choose to get dedicated support management, prioritized responses, and other professional services."}} style={{color: ""}}/>
            <div className="features-grid features-grid__center features-grid__4-col mb-5">
              {data.allContentfulTeamMember.edges.sort((a, b) => a.node.role.localeCompare(b.node.role)).map((edge) => {
                return(
                  <TeamMemberCard content={{name: edge.node.name, role: edge.node.role, img: edge.node.photo?.file.url}} />
                )})
              }
            </div>
            </Container> */}
    </Layout>
  )
}
