import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import SubHeader from "../components/subHeader"
import Button from "../components/button"
import PricingsCard from "../components/pricingCards"
import SupportCard from "../components/supportCard"
import SupportPlanFeatures from "../components/supportPlanFeatures"
import { graphql, useStaticQuery, Link } from "gatsby"
import Layout from "../components/layout"
import PricingFaqCard from "../components/pricingFaqCard"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"
import Slider from "react-slick"

export default function Pricings() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(filter: { category: { eq: "Pricing" } }) {
        edges {
          node {
            question
            category
            answer {
              childMdx {
                body
              }
            }
          }
        }
      }
      allContentfulPricingPlan {
        edges {
          node {
            title
            price
            featured
            pricingFeatures {
              title
              summary {
                summary
              }
            }
            pricingPlanTiers {
              skus
              price
              orders
            }
          }
        }
      }
      allContentfulSupportPlan {
        edges {
          node {
            title
            featured
            description {
              description
            }
            price
            supportPlanFeatures {
              title
              description
            }
          }
        }
      }
      allContentfulSupportPlanFeature {
        edges {
          node {
            title
          }
        }
      }
    }
  `)
  let counter = 0
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }
  const featuresPlan = [
    "SUPPORT COVERAGE",
    "RESPONSE TIMES",
    "CONTACTING US",
    "SUCCESS MANAGER",
    "ONBOARDING",
    "CUSTOM INSIGHTS",
    "CUSTOM IMPLEMENTATION",
    "MINIMUM DURATION",
  ]
  return (
    <Layout navbar="transparent">
      <Head
        title="Performance driven packages for scaling marketplace operations"
        description="Our pricing aims at maximizing value for our customers' specific marketplace goals, needs and requirements."
      />

      <BackgroundShape
        style={{ shape: "clip", color: "bg-case-lg", particles: false }}
      />
      <Container style={{ classes: "container spacing-to" }}>
        <div>
          <Header
            content={{
              title: "Expert help when you need it",
              text:
                "Our teams are here to support you. Businesses can choose to get dedicated support management, prioritized responses, and other professional services.",
            }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container-fluid mb-5" }}>
        {/* <Container style={{classes: "features-grid features-grid__pricing features-grid__center features-grid__4-col" }}>
                    {data.allContentfulPricingPlan.edges.sort((a, b) => a.node.price - b.node.price).map((edge) => {
                        const cardStyle = edge.node.featured ? "pricing-card__light pricing-border" : "pricing-card__light"
                        const iconStyle = edge.node.featured ? "info-dark" : "info-dark"
                        if (edge.node.title !== "Enterprise") {
                        return(
                            <PricingsCard content={{
                                title: edge.node.title,
                                price: edge.node.price,
                                pricingFeatures: edge.node.pricingFeatures,
                                pricingPlanTiers: edge.node.pricingPlanTiers,
                                icon: iconStyle
                                }}
                                style={{
                                classes: cardStyle
                                }}
                                />
                        )}
                    })}
                    <div className={`pricing-card__dark pricing-card d-flex flex-column justify-content-around`}  data-sal="slide-up" data-sal-easing="ease" data-sal-duration="600">
                        <div>
                            <h3 className="title">Enterprise</h3>
                            <p>Contact us for pricing</p>
                        </div>
                        <div className=" my-5 pt-3">
                            <p className="feature-highlight__title text-center pb-3" style={{borderTop: "0px solid white", borderBottom: "1px solid white"}}>Looking for higher order/SKU count and Enterprise grade feature set?</p>
                            <p className="feature-highlight__title text-center">Discover our Enterprise offering</p>
                        </div>
                        <div className="pricing-card__features">
                            <div className="d-flex align-items-center mt-2 position-relative">
                                <p className="m-0 ml-1"><strong>Enterprise</strong> grade cloud & support</p>
                            </div>
                            <div className="d-flex align-items-center mt-2 position-relative">
                                <p className="m-0 ml-1"><strong>Headless</strong> Rest API Architecture</p>
                            </div>
                            <div className="d-flex align-items-center mt-2 position-relative">
                                <p className="m-0 ml-1"><strong>Dedicated</strong> developer support</p>
                            </div>
                            <div className="d-flex align-items-center mt-2 position-relative">
                                <p className="m-0 ml-1"><strong>Staging</strong> environments</p>
                            </div>
                        </div>
                        <Button style={{ href:'https://share-eu1.hsforms.com/1L85aUf2hRpy18mUOtufTagf0c9r', text: "Get pricing", shape: "text-center pricing-card__btn", active:""}}/>
                    </div>
                </Container>
                <Container style={{classes: "container text-center mt-5"}}>
                    <p>Prices shown are net, i.e. exclusive of any applicable sales tax or VAT</p>
                </Container>
                <Container style={{classes: "my-5 pt-5 px-5" }}>
                <Slider {...settings}>
                    {data.allContentfulReview.edges.map((edge) => {
                        return(
                            <div>
                                <div style={{height:'260px'}} className=" px-5 mx-4 pt-4">
                                    <p style={{fontSize: '1.2rem', fontWeight: 'bolder'}}>{edge.node.author}</p>
                                    <p style={{color: '#3b77ce', fontSize: '1rem'}}>★★★★★</p>
                                    <p style={{}} className='mt-3'>{edge.node.description.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
                </Container> */}
        <Container style={{ classes: "container bg-white rounded py-4" }}>
          {/* <SubHeader content={{title: "Expert help when you need it", text: ""}} style={{color: ""}}/> */}
          {/* <Container style={{classes: "support-plans-container position-relative" }}> */}
          {/* <SupportPlanFeatures content={{featuresPlan: featuresPlan}}/>
                        { data.allContentfulSupportPlan.edges.reverse().map((edge) => {
                            const cardStyle = edge.node.featured ? "support-card__dark" : "support-card__light"
                            return(
                                <SupportCard content={{
                                    title: edge.node.title,

                                    }}
                                    price: edge.node.price.split(" ")[0],
                                    supportPlanFeatures: edge.node.supportPlanFeatures                                    style={{
                                        classes: cardStyle
                                    }}
                                    />
                                )
                            })} */}
          <div className="row">
            <div className="col-12 col-md-3">
              <p
                style={{ fontWeight: "bold", fontSize: "1.6rem" }}
                className="text-light"
              >
                .
              </p>
              {/* <p style={{fontWeight: 'bold', fontSize: '1.6rem'}} className="text-light">.</p> */}
              <p className="mt-3 text-uppercase">Success Manager</p>
              <p className="mt-3 text-uppercase">
                Contacting Us
                <br />
                <br />
              </p>
              <p className="mt-3 text-uppercase">Follow-up Meetings</p>
              <p className="mt-3 text-uppercase">Response Times</p>
              <p className="mt-3 text-uppercase">Training</p>
              <p className="mt-3 text-uppercase">Technical Account Manager</p>
              <p className="mt-3 text-uppercase">Technical Health Check</p>
            </div>
            <div className="col-12 col-md-3">
              <p style={{ fontWeight: "bold", fontSize: "1.6rem" }}>
                Essential
              </p>
              {/* <p style={{fontWeight: 'bold', fontSize: '1.6rem'}}>Free</p> */}
              <p style={{ fontWeight: "bold" }} className="mt-3 text-center">
                -
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Onport’s support portal
                <br />
                <br />
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                -
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                {"<48h"}
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Online help center
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3 text-center">
                -
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3 text-center">
                -
              </p>
            </div>
            <div className="col-12 col-md-3">
              <p style={{ fontWeight: "bold", fontSize: "1.6rem" }}>
                Professional
              </p>
              {/* <div><span style={{fontWeight: 'bold', fontSize: '1.6rem', fontFamily: 'source code pro'}}>$800</span><span>/mo</span></div> */}
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Dedicated Success Manager
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Dedicated Slack Channel during Onboarding (to a maximum period
                of 2 months)
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                1h/month
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                {"<24h"}
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Online help center
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3 text-center">
                -
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3 text-center">
                -
              </p>
            </div>
            <div className="col-12 col-md-3">
              <p style={{ fontWeight: "bold", fontSize: "1.6rem" }}>Premium</p>
              {/* <div><span style={{fontWeight: 'bold', fontSize: '1.6rem', fontFamily: 'source code pro'}}>$2000</span><span>/mo</span></div> */}
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Dedicated Success Manager
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Dedicated Slack channel
                <br />
                <br />
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                2h/month
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                {"<24h"}
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Up to 2h/month of Online Calls for 2 months
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Dedicated while Onboarding
              </p>
              <p style={{ fontWeight: "bold" }} className="mt-3">
                Included
              </p>
            </div>
          </div>
          <div className="text-center">
            <div
              className="darkblue mx-auto mt-5 text-light py-4 px-5"
              style={{ borderRadius: "8px", display: "inline-block" }}
            >
              <p
                className="mb-3"
                style={{ fontWeight: "bold", fontSize: "1.6rem" }}
              >
                Enterprise
              </p>
              <p className="mb-3" style={{ fontWeight: "", fontSize: "1rem" }}>
                Looking to have different coverages on your Support package?
              </p>
              <p className="mb-3" style={{ fontWeight: "", fontSize: "1rem" }}>
                Discover our Enterprise support offering
              </p>
              <Link className="btnOn dark-bg" to="/contacts/enterprise">
                Talk to sales
              </Link>
            </div>
          </div>
          {/* </Container> */}
        </Container>
        {/* <Container style={{classes: "container mt-5" }}>
                    <SubHeader content={{title: "Pricing FAQs", text: "Find out how our customers use Onport to help increase their sales and grow their retail businesses."}} style={{color: ""}}/>
                        {data.allContentfulFaq.edges.map((edge) => {
                            counter += 1
                            return(
                                <PricingFaqCard content={{question: edge.node.question, answer: edge.node.answer.childMdx.body, counter: counter}}/>
                            )
                        })}
                </Container> */}
      </Container>
    </Layout>
  )
}
