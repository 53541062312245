import React from "react"
import { Link } from "gatsby"
import TitleCategory from "./new/titleCategory/titleCategory"
import Block from "./new/block/block"
import Paragraph from "./new/paragraph/paragraph"
import SubHeader from "./new/subHeader/subHeader"
import Grid from "./new/grid/grid"

const Onboarding = props => {
  return (
    <Link to={`/onboarding/${props.content.slug}`}>
      <Block style="row onboarding-card">
        <Block style="col-12 col-md-4">
          <img
            src={props.content.src}
            className="my-5 home-on-img"
            data-sal="slide-left"
            data-sal-duration="600"
          />
        </Block>
        <Block style="col-12 col-md-8" anim="slide-up">
          <TitleCategory title={props.content.category} />
          <SubHeader title={props.content.title} style="dark" />
          <Paragraph text={props.content.summary} />
        </Block>
      </Block>
      {/* <div className="onboarding-card">
          <img src={props.content.src} className="onboarding-card__img" alt={props.content.title}/>
          <div className="onboarding-card__text">
            <h3 className="onboarding-card__title">{props.content.title}</h3>
            <p className="onboarding-card__description">{props.content.summary}</p>
          </div>
        </div> */}
    </Link>
  )
}

export default Onboarding
