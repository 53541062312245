import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import BackgroundShape from "../components/backgroundShape"
import OnboardingCard from "../components/onboardingCard"
import Layout from "../components/layout"
import Head from "../components/head"
import SubHeader from "../components/new/subHeader/subHeader"
import TitleCategory from "../components/new/titleCategory/titleCategory"
import Paragraph from "../components/new/paragraph/paragraph"
import Alert from "../components/new/alert/alert"

export default function Onboarding() {
  return (
    <Layout navbar="darkblue">
      <Head
        title="Onboarding"
        description="Find out how our customers use Onport to help increase their sales and grow their retail businesses"
      />
      <BackgroundShape style={{ shape: "clip-fixed-big", color: "darkblue" }} />
      <Container style={{ classes: "container" }}>
        <div style={{ textAlign: "center", paddingBottom: "3rem" }}>
          <TitleCategory title="SUPPORT" style="lightBlue" />
          <SubHeader title="Onboarding" style="light" />
          <Paragraph
            text="Even if you're just starting a new business or if your business is already growing, your vendors are a very important part of your plan as their products will be one of the keys to your success as a marketplace.<br/>If your vendors don't have an e-commerce platform, that's not a problem, we also have more manual solutions that could help you still sell the products you truly believe in."
            style="light w-75 mx-auto"
          />
        </div>
      </Container>
      <Alert
        icon="⚠️"
        text="Make sure you've <a href='https://jettisupport.zendesk.com/hc/en-us/sections/4405441081490-Integrations' target='_blank'>successfully connected your e-commerce platform</a> to your account before you begin the onboarding process below. It's also necessary to <a href='https://jettisupport.zendesk.com/hc/en-us/sections/4405433157522-Integrations' target='_blank'>create and connect with at least one dropship providers</a> before you decide what your strategy will be."
      />
      <Container style={{ classes: "container mx-auto mb-5" }}>
        <OnboardingCard
          content={{
            slug: "create-product",
            category: "INVENTORY",
            src:
              "https://taact-brand-assets.netlify.app/assets/illustrations/inventory.svg",
            title: "Product catalog",
            summary:
              "Whether you’re starting a new online store business or you’re already a marketplace looking to expand and welcome new vendors, you will need a strategy. Create consistency whenever it’s possible. Differentiate yourself from other stores and make the products you sell more appealing. Make sure the vendors you work with send you the product information you need, that they fill every single detail of a product, send good quality pictures, and respect the values of your marketplace, your store, and your business.",
          }}
        />
        <OnboardingCard
          content={{
            slug: "shipping",
            category: "SHIPPING",
            src:
              "https://taact-brand-assets.netlify.app/assets/illustrations/send.svg",
            title: "Shipping strategies",
            summary:
              "If you’re running a marketplace, the shipping strategy is key to the growth and success of your business. It can really be the difference between profitability or loss according to what you define. At times we see a lot of marketplaces losing money with shipping and it can be difficult to find the source of the problem.",
          }}
        />
        <OnboardingCard
          content={{
            slug: "order",
            category: "ORDERS",
            src:
              "https://taact-brand-assets.netlify.app/assets/illustrations/send-order.svg",
            title: "Orders management",
            summary:
              "We all know there's no profit without sales and we also know that it can be a challenge to manage a marketplace with multiple vendors' orders to take care of. We offer a solution based on processing the sales automatically from your store into Onport, splitting them across vendors, and creating dropship purchases for each one of them so they can ship the items. After that, payments, shipping, and commission are all calculated for you. The main question we will be helping you answer is how do you want your vendors to receive orders.",
          }}
        />
        <OnboardingCard
          content={{
            slug: "returns",
            category: "RETURNS",
            src:
              "https://taact-brand-assets.netlify.app/assets/illustrations/shipping-updates.svg",
            title: "Returns",
            summary:
              "Wether your customers are returning a product because they changed their mind, or simply didn’t love the product, you will be able to easily keep track of all returns and process them seamlessly. Full or partial returns can be raised by the customer and approved or not by you. Once your vendors receive the products and validate the return, you will be able to refund your customer or even help them find a new product to love. ",
          }}
        />
        <OnboardingCard
          content={{
            slug: "payments",
            category: "PAYMENTS",
            src:
              "https://taact-brand-assets.netlify.app/assets/illustrations/payment.svg",
            title: "Payments",
            summary:
              "In Onport you can easily manage Vendor Payments where you can define how, when and how much your vendor will be paid. We allow the payment process to be customised at a vendor level, so that you can ensure all agreements made with your partners. We support integrations with other platforms to make it simple to fully automate your vendor payments. And, if your vendor isn't using either, we can even setup automated ACH/Bank transfer payments.",
          }}
        />
        <OnboardingCard
          content={{
            slug: "tax-and-invoicing",
            category: "PAYMENTS",
            src:
              "https://taact-brand-assets.netlify.app/assets/illustrations/invoice.svg",
            title: "Tax and Invoicing",
            summary:
              "Dealing with taxes in a marketplace can be challenging due to the many tax rules that differ from country to country. Besides that, there are two ways an ecommerce marketplace can manage their taxes: either manage sales and sales tax for their vendors or function as a platform through which vendors handle their own sales and taxes. ",
          }}
        />
      </Container>
    </Layout>
  )
}
