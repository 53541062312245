import React from "react"
import Particles from "react-particles-js"
import "./nodeGarden.scss"

const NodeGarden = () => {
  return (
    <div className="particles-container">
      <Particles
        params={{
          particles: {
            move: {
              speed: 0.2,
            },
            size: {
              value: 5,
              random: true,
            },
            line_linked: {
              enable: false,
            },
            opacity: {
              value: 0.1,
              random: false,
              anim: {
                enable: true,
                speed: 0.2,
                opacity_min: 0.1,
                sync: false,
              },
            },
          },
        }}
      />
    </div>
  )
}

export default NodeGarden
