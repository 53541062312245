import React from "react"
import ReactHtmlParser from "react-html-parser"
import "./alert.scss"

const Alert = ({ text, icon, style, anim, delay, duration }) => {
  return (
    <div
      className={`alert ${style || ""}`}
      data-sal={anim}
      data-sal-delay={delay || "300"}
      data-sal-easing="ease"
      data-sal-duration={duration || "600"}
    >
      <span className="alert-icon">{icon}</span>
      <p>{ReactHtmlParser(text)}</p>
    </div>
  )
}

export default Alert
