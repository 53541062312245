import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundShape from "../components/backgroundShape"
import Container from "../components/container"
import Header from "../components/header"
import FeatureCardMd from "../components/featureCardMd"
import Layout from "../components/layout"
import Head from "../components/head"

const FeaturePage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFeature {
        edges {
          node {
            title
            slug
            icon
          }
        }
      }
    }
  `)

  return (
    <Layout navbar="transparent">
      <Head
        title="A complete set of features to enable you to scale faster."
        description="Our cloud based software helps you connect with multiple vendors and manage all your marketplace operations from a single platform."
      />
      <BackgroundShape
        style={{ shape: "clip", color: "bg-blog-lg", particles: false }}
      />
      <Container style={{ classes: "container spacing-top mb-5" }}>
        <div>
          <Header
            content={{
              title: "The complex made accessible",
              text:
                "Our cloud based software helps you connect with multiple vendors and manage all your ecommerce operations from a single platform",
            }}
            style={{ color: "text-center" }}
          />
          <div className="features-grid features-grid__center features-grid__4-col">
            {data.allContentfulFeature.edges.map(edge => {
              return (
                <FeatureCardMd
                  content={{
                    slug: edge.node.slug,
                    title: edge.node.title,
                    icon: edge.node.icon,
                  }}
                  style={{ color: "" }}
                />
              )
            })}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default FeaturePage
