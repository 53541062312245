import { Link } from "gatsby"
import React from "react"
import logo from "../../../assets/icons/logo.svg"
import Button from "../../button"
import Paragraph from "../paragraph/paragraph"
import Title from "../title/title"
import Container from "../container/container"
import Grid from "../grid/grid"
import Flex from "../flex/flex"
import Block from "../block/block"
import "./footerMin.scss"

const FooterMin = props => {
  return (
    <Container style="fluid brandDarker py-5 footerOnMin">
      <Container style="">
        <Block style="row">
          <Block style="col-12 col-md-6">
            <img src={logo} className="logo mt-3" alt="logo" />
            {/* <Paragraph text='Navigate your business with Onport, a multi-vendor marketplace solution to scale your operations.' style='text-light mt-3'/> */}
          </Block>
          <Block style="col-12 col-md-6">
            <Block style="row w-100 justify-content-between">
              <Block style="col-12 col-md-6">
                <Title title="Product" style="text-light" />
                <Link className="text-light" to="/partners">
                  Integrations
                </Link>
                <Link className="text-light" to="/features">
                  Features
                </Link>
              </Block>
              <Block style="col-12 col-md-6">
                <Title title="Resources" style="text-light" />
                <Link to="/casestudies">Case studies</Link>
                <Link to="/blog">Blog</Link>
                <Link to="/about">About</Link>
              </Block>
            </Block>
          </Block>
        </Block>
        <Block style="row mt-5">
          <Block style="col-12 col-md-6">
            <Paragraph
              text="© 2024 Onport. All rights reserved"
              style="text-light"
            />
          </Block>
          <Block style="col-12 col-md-6 text-right">
            <a
              className="social"
              href="https://www.youtube.com/channel/UCpHTLMOOXt-mhrkxNyWAPiw"
            >
              <img
                src={require("../../../assets/icons/youtube.svg")}
                alt="jetti-youtube"
              />
            </a>
            <a className="social" href="https://twitter.com/onport_hq">
              <img
                src={require("../../../assets/icons/twitter.svg")}
                alt="jetti-twitter"
              />
            </a>
            <a
              className="social"
              href="https://www.linkedin.com/company/onport/"
            >
              <img
                src={require("../../../assets/icons/linkedin.svg")}
                alt="jetti-linkedin"
              />
            </a>
          </Block>
        </Block>
      </Container>
    </Container>
  )
}

export default FooterMin
