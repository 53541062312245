import React from "react"
import Navbar from "../../components/navbar"
import Container from "../../components/container"
import Header from "../../components/header"
import Layout from "../../components/layout"
import BackgroundShape from "../../components/backgroundShape"
import Head from "../../components/head"
import TableEntryVendor from "../../components/tableEntryVendor"
import TableContacts from "../../components/tableContacts"
import { graphql, useStaticQuery } from "gatsby"

export default function VendorStatus() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulVendorIntegrations {
        edges {
          node {
            title
            fetchProducts
            inventorySync
            createOrders
            fetchFulfillments
            importCancellations
            exportCancellations
            taxOnPayouts
            fetchPerformance
            support
          }
        }
      }
    }
  `)

  return (
    <Layout navbar="transparent">
      <Head
        title="Vendor Integrations"
        description="From basic dropshipping & automation to Advanced and custom reporting, we’ve got you covered. Find the right package for your business"
      />

      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "gradient",
          particles: true,
        }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{
              title: "Vendor Integrations",
              text: "Check the current capabilities of our integrations",
            }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container mt-5 pt-5" }}>
        <div
          className="vendor-table-entry"
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-delay="100"
          data-sal-duration="800"
        >
          <p className="table-header"></p>
          <p className="table-header">
            Fetch
            <br />
            Products
          </p>
          <p className="table-header">
            Inventory
            <br />
            Sync
          </p>
          <p className="table-header">
            Create
            <br />
            Orders
          </p>
          <p className="table-header">
            Fetch
            <br />
            Fulfillments
          </p>
          <p className="table-header">
            Import
            <br />
            Cancellations
          </p>
          <p className="table-header">
            Export
            <br />
            Cancellations
          </p>
          <p className="table-header">
            Tax on
            <br />
            Payouts
          </p>
          <p className="table-header">
            Fetch
            <br />
            Performance
          </p>
          <p className="table-header">
            Support
            <br />
            Article
          </p>
        </div>
        {data.allContentfulVendorIntegrations.edges.map(edge => {
          return (
            <TableEntryVendor
              data={{
                title: edge.node.title,
                fetchProducts: edge.node.fetchProducts,
                inventorySync: edge.node.inventorySync,
                createOrders: edge.node.createOrders,
                fetchFulfillments: edge.node.fetchFulfillments,
                importCancellations: edge.node.importCancellations,
                exportCancellations: edge.node.exportCancellations,
                taxOnPayouts: edge.node.taxOnPayouts,
                fetchPerformance: edge.node.fetchPerformance,
                support: edge.node.support,
              }}
            />
          )
        })}
        <TableContacts title="Are your vendors in a platform not listed above? " />
      </Container>
    </Layout>
  )
}
