import React from "react"
import { Link } from "gatsby"
import "./banner.scss"

const Banner = ({ children, style }) => {
  return (
    <div className="bannerOn d-flex justify-content-around">
      <div className="d-flex align-items-center ">
        <p style={{ fontWeight: "800" }}>
          Why Retailers Are Turning to the Marketplace Model
        </p>
        <Link to="https://onport.com/blog/why-retailers-are-turning-to-the-marketplace-model-to-scale-their-ecommerce-operations">
          Download Playbook
        </Link>
      </div>
    </div>
  )
}

export default Banner
