import React from "react"
import Navbar from "../../components/navbar"
import BackgroundShape from "../../components/backgroundShape"
import Container from "../../components/container"
import Header from "../../components/header"
import Head from "../../components/head"
import Layout from "../../components/layout"
import OnboardingBlock from "../../components/new/onboardingBlock/onboardingBlock"
import OnboardingCardSm from "../../components/onboardingCardSm"
import { Link } from "gatsby"

const Shipping = () => {
  return (
    <Layout navbar="darkblue">
      <Head
        title="Tax"
        description="Learn how to connect with multiple vendors, manage your inventory, sales, customers and more with our step-by-step tutorials"
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "darkblue",
          particles: true,
        }}
      />
      <Container style={{ classes: "container" }}>
        <div className="position-relative">
          <Header
            content={{
              title: "Tax and Invoicing",
              text:
                "Managing your sales taxes and invoicing your vendors can be challenging given that rules may be different in each country. Setting a strong plan and managing all in one place, will help you keep track of everything.",
            }}
            style={{ color: "text-center" }}
          />
          <Link
            to="/onboarding"
            className="btnOn dark-bg"
            style={{ position: "absolute", top: "246px", left: "-14px" }}
          >
            ← Back
          </Link>
        </div>
      </Container>

      <Container style={{ classes: "container mb-5" }}>
        <div
          style={{
            background: "#F4F6F8",
            padding: "40px 0",
            margin: "2rem -15px 0 -15px",
          }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "1rem",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            How can i setup my <span style={{ color: "#3b77ce" }}>taxes</span>?
          </p>
          <p
            style={{ fontWeight: "300", marginTop: "8px", textAlign: "center" }}
          >
            Onport can automatically apply tax to the products and shipping on a
            dropship purchase order.
            <br />
            Tax and dropshipping can be complex. As such, we recommend
            consulting a tax specialist to understand the most relevant setup
            for your specific circumstances.
          </p>
          <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="hide"
                link="https://help.jetti.io/hc/en-us/articles/360021089000-Calculating-Tax-for-Dropship-Orders"
                title="Do not generate sales tax"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="order-sync"
                link="https://help.jetti.io/hc/en-us/articles/360021089040-TaxJar"
                title="Taxjar"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="returns"
                link="https://help.jetti.io/hc/en-us/articles/360021089000-Calculating-Tax-for-Dropship-Orders"
                title="Use the same rates paid by the customer"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="tax"
                link="https://help.jetti.io/hc/en-us/articles/360021089000-Calculating-Tax-for-Dropship-Orders"
                title="Manual"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container style={{ classes: "container mb-5" }}>
        <div
          style={{
            background: "#F4F6F8",
            padding: "40px 0",
            margin: "2rem -15px 0 -15px",
          }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "1rem",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            Will you charge vendors for{" "}
            <span style={{ color: "#3b77ce" }}>tax</span>?
          </p>
          <p
            style={{ fontWeight: "300", marginTop: "8px", textAlign: "center" }}
          >
            There are features that will allow you to define tax rates within
            Onport.
          </p>
          <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="warehouse-alt"
                link="https://help.jetti.io/hc/en-us/articles/8907609723036"
                title="Dropship provider's shop"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="shipping"
                link="https://help.jetti.io/hc/en-us/articles/8907609723036"
                title="Shipping rates"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="create-order"
                link="https://help.jetti.io/hc/en-us/articles/8907609723036"
                title="Final price"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                icon="warehouse"
                link="https://help.jetti.io/hc/en-us/articles/8907609723036"
                title="NET price"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container style={{ classes: "container mb-5" }}>
        <div
          style={{
            background: "#F4F6F8",
            padding: "40px 0",
            margin: "2rem -15px 0 -15px",
          }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "1rem",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            Who is going to <span style={{ color: "#3b77ce" }}>invoice</span>{" "}
            the end customer?
          </p>
          <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
            <div className="col-12 col-md-6">
              <OnboardingBlock
                icon="vendor-alt"
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-1"
                title="Reverse model: tax calculation for reverse invoices"
              />
            </div>
            <div className="col-12 col-md-6">
              <OnboardingBlock
                icon="rules"
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-2"
                title="Commission based model: tax calculation for commission invoices"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container style={{ classes: "container mb-5" }}>
        <div
          style={{
            background: "#F4F6F8",
            padding: "40px 0",
            margin: "2rem -15px 0 -15px",
          }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "1rem",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            In a reversed model, if a vendor wants to apply a{" "}
            <span style={{ color: "#3b77ce" }}>different tax rate</span>, how
            can it be set up?
          </p>
          <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
            <div className="col-12 col-md-3">
              <OnboardingBlock
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-3"
                title="Use the default tax rate"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-3"
                title="Always apply the tax rate"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-3"
                title="Only apply for EU destinations"
              />
            </div>
            <div className="col-12 col-md-3">
              <OnboardingBlock
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-3"
                title="Only apply for domestic orders"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container style={{ classes: "container mb-5" }}>
        <div
          style={{
            background: "#F4F6F8",
            padding: "40px 0",
            margin: "2rem -15px 0 -15px",
          }}
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="300"
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "1rem",
              marginTop: "8px",
              textAlign: "center",
            }}
          >
            Do you want to apply{" "}
            <span style={{ color: "#3b77ce" }}>the tax</span> on commission?
          </p>
          <div className="row align-items-start justify-content-around mt-5 w-75 mx-auto">
            <div className="col-12 col-md-6">
              <OnboardingBlock
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-3"
                title="Yes"
              />
            </div>
            <div className="col-12 col-md-6">
              <OnboardingBlock
                link="https://help.jetti.io/hc/en-us/articles/8907693747484-Invoicing-the-end-customer#heading-3"
                title="No"
              />
            </div>
          </div>
        </div>
      </Container>

      <Container style={{ classes: "container mb-5" }}>
        <div className="row">
          <OnboardingCardSm
            content={{
              slug: "payments",
              category: "ORDERS",
              step: "← Previous",
              src:
                "https://taact-brand-assets.netlify.app/assets/illustrations/inventory.svg",
              title: "Payments",
            }}
          />
          {/* <OnboardingCardSm content={{
                    slug: 'payments', 
                    category: 'PAYMENTS',
                    step:'Next →', 
                    src: "https://taact-brand-assets.netlify.app/assets/illustrations/send-order.svg", 
                    title: "Payments", 
                    }}/> */}
        </div>
      </Container>
    </Layout>
  )
}

export default Shipping
