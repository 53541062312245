import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import BackgroundShape from "../components/backgroundShape"
import Layout from "../components/layout"
import Head from "../components/head"
import SubHeader from "../components/new/subHeader/subHeader"
import TitleCategory from "../components/new/titleCategory/titleCategory"
import Paragraph from "../components/new/paragraph/paragraph"
import FooterMin from "../components/new/footerMin/footerMin"
import HubspotContactForm from "../components/hubspotContactForm"
import { Link } from "gatsby"
import logo from "../assets/icons/logo.svg"
import Block from "../components/new/block/block"
import HeroAnimation from "../assets/images/anim-backup.svg"

export default function Onboarding() {
  return (
    <div className="layout">
      <div className="content">
        <Head
          title="Partners form"
          description="Find out how our customers use Onport to help increase their sales and grow their retail businesses"
        />
        {/* <Navbar style={{classes: "position-absolute darkblue"}}/> */}
        <div className="pt-4 ml-5">
          <Link to="/" className={"title"}>
            <img src={logo} className="logo mr-5" alt="logo" />
          </Link>
        </div>
        <BackgroundShape
          style={{
            shape: "clip-fixed-big overflow-hidden",
            color: "darkblue",
            particles: true,
          }}
        />
        <Container style={{ classes: "container position-relative" }}>
          <div
            style={{
              textAlign: "center",
              marginTop: "3rem",
              paddingBottom: "3rem",
            }}
          >
            {/* <TitleCategory title='Parters form' style='lightBlue'/> */}
            <SubHeader title="Partner With Onport " style="light" />
            <Paragraph
              text="We believe in the power of collaboration to drive innovation.<br/>To achieve that we rely on our partnerships ecosystem to build the future of marketplaces together."
              style="light w-75 mx-auto"
            />
          </div>
          <div
            className="desktop-only position-absolute pt-4"
            style={{ top: "-100px", right: "-170px", zIndex: "-1" }}
          >
            <img
              src={HeroAnimation}
              style={{ height: "320px" }}
              data-sal="slide-left"
              data-sal-duration="600"
            />
          </div>
        </Container>
        <Container style={{ classes: "container row mx-auto" }}>
          <div className="bg-white w-75 mx-auto p-5 mb-5">
            <HubspotContactForm />
          </div>
        </Container>
      </div>
      <FooterMin />
    </div>
  )
}
