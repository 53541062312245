import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import Head from "../components/head"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"

export default function EnterpriseContact() {
  const checkForm = () => {
    if (
      document.getElementById("inputState").value === "Other" ||
      document.getElementById("inputState").value ===
        "Partner Website (please let us know what partner)"
    ) {
      document.getElementById("extra").style.display = "block"
    } else {
      console.log("lol")
      document.getElementById("extra").style.display = "none"
    }
  }

  return (
    <Layout navbar="darkblue">
      <Head
        title="Enterprise Contact"
        description="Schedule a call with us to learn more about our pricing and how we can help you manage an Enterprise level Marketplace"
      />
      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "dark-blue",
          particles: true,
        }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{ title: "Contact us", text: "" }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container" }}>
        <div className="d-flex">
          <form
            className="pt-5 pr-5 mt-5 w-100"
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/thanks"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div class="form-row">
              <div class="form-group col-6">
                <label for="Last Name">First Name*</label>
                <input
                  type="text"
                  class="form-control"
                  id="FirstName"
                  placeholder="First Name"
                  name="FirstName"
                  required
                />
              </div>
              <div class="form-group col-6">
                <label for="Last Name">Last Name*</label>
                <input
                  type="text"
                  class="form-control"
                  id="LastName"
                  placeholder="Last Name"
                  name="LastName"
                  required
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <label for="Work Email">Work Email*</label>
                <input
                  type="email"
                  class="form-control"
                  id="Work Email"
                  placeholder="Work Email"
                  name="Email"
                  required
                />
              </div>
              <div class="form-group col-6">
                <label for="Company">Company*</label>
                <input
                  type="text"
                  class="form-control"
                  id="Company"
                  placeholder="Company"
                  name="Company"
                  required
                />
              </div>
            </div>
            <div className="form-info dark-blue text-white p-3 mt-1 mb-3 ">
              <p>
                Fill in the rest of the form to help us giving you better
                support
              </p>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <label for="Phone Number">Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="PhoneNumber"
                  placeholder="PhoneNumber"
                  name="PhoneNumber"
                />
              </div>
              <div class="form-group col-6">
                <label for="Country">Country</label>
                <input
                  type="text"
                  class="form-control"
                  id="County"
                  placeholder="Country"
                  name="Country"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <label for="About Us">How did you hear about us</label>
                <select
                  onChange={checkForm}
                  id="inputState"
                  class="form-control"
                  name="AboutUs"
                >
                  <option selected class="test-option">
                    Choose...
                  </option>
                  <option>Peer Referral</option>
                  <option>Shopify App Store</option>
                  <option>Google / Search Engine</option>
                  <option>LinkedIn</option>
                  <option>Social Media (Facebook, Youtube)</option>
                  <option>
                    Partner Website (please let us know what partner)
                  </option>
                  <option>Other</option>
                </select>
              </div>
              <div class="form-group col-6">
                <label for="Need">What is your primary business need?</label>
                <input
                  type="text"
                  class="form-control"
                  id="Need"
                  placeholder="What is your primary business need?"
                  name="Need"
                />
              </div>
            </div>
            <div class="form-row">
              <div
                class="form-group col-12"
                id="extra"
                style={{ display: "none" }}
              >
                <label for="PleaseSpecify">Please specify</label>
                <input
                  type="text"
                  class="form-control"
                  id="PleaseSpecify"
                  placeholder="..."
                  name="PleaseSpecify"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-6">
                <label for="TypeofBusiness">Type of Business</label>
                <input
                  type="text"
                  class="form-control"
                  id="TypeofBusiness"
                  placeholder="Type of Business"
                  name="TypeofBusiness"
                />
              </div>
              <div class="form-group col-6">
                <label for="Your Role">Your Role</label>
                <input
                  type="text"
                  class="form-control"
                  id="YourRole"
                  placeholder="Your Role"
                  name="Role"
                />
              </div>
            </div>
            <button type="submit" class="btn-square btn-square__full m-0">
              Submit
            </button>
          </form>
          <div className="pt-5 mt-5 text-white">
            <div className="dark-blue px-3 py-5">
              <p className="feature-highlight__title mb-3">
                Learn More About Onport Enterprise Pricing
              </p>
              <p className="feature-highlight__description mb-1">
                Schedule a call with us to learn more about our pricing and how
                we can help you manage an Enterprise level Marketplace
              </p>
              <p className="feature-highlight__description">
                {" "}
                One of our reps will reach out to you to
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}
