import React from "react"
import Navbar from "../components/navbar"
import Container from "../components/container"
import Header from "../components/header"
import Layout from "../components/layout"
import BackgroundShape from "../components/backgroundShape"
import Head from "../components/head"

export default function PrivacyCandidate() {
  return (
    <Layout navbar="transparent">
      <Head
        title="Privacy Policy of the Onport Platform"
        description="The Privacy Policy of the Onport Platform"
      />

      <BackgroundShape
        style={{
          shape: "clip-fixed-small",
          color: "bg-privacy-sm",
          particles: false,
        }}
      />
      <Container style={{ classes: "container" }}>
        <div>
          <Header
            content={{ title: "Candidate Privacy", text: "" }}
            style={{ color: "text-center" }}
          />
        </div>
      </Container>
      <Container style={{ classes: "container privacy mb-5" }}>
        <h2>Candidate Privacy Policy</h2>
        <p>Effective Date: August, 2024</p>
        <p>
          We are JBUX Limited (&ldquo;Onport&rdquo;, &ldquo;we&rdquo;,
          &ldquo;us&rdquo;, &ldquo;our&rdquo;), and we are committed to
          protecting your privacy.
        </p>
        <p>
          Onport is the controller for the purposes of the General Data
          Protection Regulation (the &ldquo;GDPR&rdquo;) a company registered in
          England and Wales (Company No. 09496834), with its registered office
          located at 1 Manor Drive, Bath, BA1 7TY, United Kingdom, and manages
          &ldquo;jetti.io&rdquo;/&rdquo;onport.com&rdquo; (the
          &ldquo;website&rdquo;).{" "}
        </p>
        <p>
          This privacy policy sets out the basis on which we process any
          personal data that you provide to us through our recruiting software,
          cloud-based storage and candidate tracking services provider (the
          &ldquo;Provider&rdquo;) and other data we receive from you or third
          parties (such as referees) in connection with your job application.
        </p>
        <p>
          By submitting your job application and/or using our Provider&rsquo;s
          Portal (the &ldquo;Portal&rdquo;) you acknowledge and agree to the
          practices described in this policy.
        </p>
        <p>
          Please read the following carefully to understand our views and
          practices regarding your data and how we will treat it.{" "}
        </p>
        <p>
          If you have any questions or concerns about this Policy, including any
          requests to exercise your legal rights, you can contact us at
          support@onport.com.
        </p>
        <h2>A. THE TYPES OF DATA WE RECEIVE AND USE</h2>
        <p>We will receive and use the following information about you:</p>
        <p>
          Information you provide as part of your application:&nbsp;You will
          provide us information about you to apply for a role or by
          corresponding with us by phone, e-mail, or otherwise. This includes
          information you provide when you fill and submit the recruitment form,
          such as your name, contact information, work experience, educational
          qualifications, ability to work in the country for which you are
          applying for employment, and any information you choose to submit on
          or upload on the form (such as information from a CV).
        </p>
        <p>
          Information we collect about your usage of our career website:&nbsp;We
          automatically collect certain data from you when you use our website,
          including unique device identifiers, information collected on your
          usage of the Portal, and information regarding your use of the Portal
          (&ldquo;Usage Information&rdquo;).
        </p>
        <p>
          Information from third parties:&nbsp;We receive information from third
          parties in connection with your application, such as referees.
        </p>
        <h2>B. HOW WE USE YOUR INFORMATION AND LEGAL BASIS FOR PROCESSING</h2>
        <ol start="1">
          <li>We use your information to:</li>
        </ol>
        <ul>
          <li>identify and evaluate you for employment positions;</li>
          <li>determine and validate your qualifications for employment;</li>
          <li>
            conduct background checks to the extent permitted under applicable
            law (including checking references, qualifications);
          </li>
          <li>
            if you are a successful candidate, create an employment record;
          </li>
          <li>provide you with user support of our Career website;</li>
          <li>communicate with you;</li>
          <li>
            assess if your profile matches with other positions available;
          </li>
          <li>
            send you notifications about new positions that match your profile;
          </li>
          <li>
            improve our Careers website and to ensure content from our Careers
            website is presented in the most effective manner for you and your
            device;
          </li>
          <li>
            administer our Careers website and for internal operations,
            including troubleshooting, data analysis, testing, research,
            statistical, and survey purposes; or
          </li>
          <li>keep our Careers website safe and secure; or</li>
          <li>
            develop our Careers website and improve our recruitment processes.
          </li>
        </ul>
        <ol start="2">
          <li>Our Legal Basis:</li>
        </ol>
        <p>
          We rely on several legal basis for processing your information, as
          explained below:
        </p>
        <p>
          Pre-Contractual:&nbsp;Our legal basis for processing your information
          is to undertake the necessary pre-contractual steps in order to
          perform a contract with you.
        </p>
        <p>
          Legal Obligations:&nbsp;We will have to ensure that we comply with our
          legal obligations.
        </p>
        <p>
          Legitimate Interests:&nbsp;We also have legitimate interests in
          processing such information, such as verifying your professional track
          record, monitoring employment diversity, keeping our Portal secure and
          improving our recruitment process.
        </p>
        <h2>C. HOW WE SHARE YOUR INFORMATION</h2>
        <p>
          We share your information with selected recipients. These categories
          of recipients include our Provider, which we use to collect and
          process your information, besides any referee from your previous jobs.
        </p>
        <p>
          When transferring personal information outside the EEA, we rely on the
          Commission&rsquo;s adequacy decisions, where applicable, or the
          Commission&rsquo;s model contractual clauses for the transfer of
          personal data to third countries (i. e, the standard contractual
          clauses).
        </p>
        <h2>D. WHERE WE STORE YOUR INFORMATION</h2>
        <p>
          The information that we collect from you will be stored by our
          Provider, which might store it outside of the European Economic Area
          ("EEA"). Depending on the specific location of the data storage and
          shall the Provider store your personal data outside of the EEA, we
          rely on different adequacy measures, as applicable:
        </p>
        <p>
          1. Adequacy Decisions:&nbsp;The European Commission may determine
          whether a country outside the EU offers an adequate level of data
          protection;
        </p>
        <p>
          2. Model Clauses:&nbsp;We rely in the Commission&rsquo;s model
          contracts for the transfer of personal data to third countries (i.e.
          the standard contractual clauses);
        </p>
        <h2>E. THE SECURITY OF YOUR PERSONAL DATA</h2>
        <p>
          Keeping you and your personal information secure is very important to
          us. Once we have received your information, we will take appropriate
          technical and organizational measures to safeguard your personal data
          against loss, theft, and unauthorized use, access, or modification,
          including using HTTPS, TLS or other industry-standard encryption
          technology, to encrypt the personal data that you send us during the
          order process.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we will do our best to protect your
          personal data, we cannot guarantee the security of your information
          transmitted through the Portal; any transmission is at your own risk.
        </p>
        <h2>F. DATA RETENTION</h2>
        <p>
          We will retain your information for the entire moment of the
          recruitment process and up to three (3) years after your last
          interaction with us or with the Portal, if your application was
          unsuccessful, for the purposes of verifying your suitability for
          future vacancies, unless you tell us otherwise. When your application
          is successful, your information will be transferred to your employee
          file and will be subject to the retention periods associated with that
          file.
        </p>
        <p>
          Your information will be retained for longer than the abovementioned
          periods if required by law or a court order and/or as needed to defend
          or pursue legal claims.
        </p>
        <h2>G. YOUR PRIVACY RIGHTS AND HOW TO EXERCISE THEM</h2>
        <p>
          You have certain rights in relation to the personal data we hold about
          you. Some of these only apply in certain circumstances as set out in
          more detail below. We also set out how to exercise those rights.
          Please note that we will require you to verify your identity before
          responding to any requests to exercise your rights and that can
          include asking a set of security questions to ensure it is you. When
          you have appointed someone else to do the request on your behalf, that
          person and/or organization needs to show a valid document that
          demonstrates they are entitled to act on your behalf. We must respond
          to a request by you to exercise those rights without undue delay and
          at least within one month (although this may be extended by a further
          two months in certain circumstances) To exercise any of your rights,
          please contact us at support@onport.com.
        </p>
        <p>You have the following rights in relation to your personal data:</p>
        <p>
          Access:&nbsp;to access the personal data held by Onport about you and
          certain information about how we use it and who we share it with;
        </p>
        <p>
          Portability:&nbsp;in certain circumstances, you have the right to
          receive or ask us to provide your personal data to a third party in a
          structured, commonly used and machine-readable format, although we
          will not provide you with certain personal data if to do so would
          interfere with another&rsquo;s individual&rsquo;s rights (e.g. where
          providing the personal data we hold about you would reveal information
          about another person) or where another exemption applies (we can only
          do so where it is technically feasible; we are not responsible for the
          security of the personal data or its processing once received by the
          third party);
        </p>
        <p>
          Correction:&nbsp;to correct any personal data held about you that is
          inaccurate and have incomplete data completed (including by the
          provision of a supplementary statement). Where you request the
          correction, please explain in detail why you believe the personal data
          we hold about you to be inaccurate or incomplete so that we can assess
          whether a correction is required. Please note that while we assess
          whether the personal data we hold about you is inaccurate or
          incomplete, you may exercise your right to restrict our processing of
          the applicable data as described below;
        </p>
        <p>
          Erasure:&nbsp;that we erase the personal data we hold about you in
          certain circumstances referred to below: you believe that it is no
          longer necessary for us to hold the personal data we hold about you;
          we are processing the personal data we hold about you on the basis of
          our legitimate interest and you object to such processing. Please
          provide us with detail as to your reasoning so that we can assess
          whether there is an overriding interest for us to retain such personal
          data; or you believe the personal data we hold about you is being
          unlawfully processed by us; Also note that you may exercise your right
          to restrict our processing of your personal data whilst we consider
          your request as described below. Please provide as much detail as
          possible on your reasons for the request to assist us in determining
          whether you have a valid basis for erasure. We will retain the
          personal data if there are valid grounds under law for us to do so
          (e.g., for the defense of legal claims or freedom of expression) but
          we will let you know if that is the case;
        </p>
        <p>
          Restriction of Processing to Storage Only:&nbsp;to require us to stop
          processing the personal data we hold about you other than for storage
          purposes in certain circumstances. Please note, however, that if we
          stop processing the personal data, we may use it again if there are
          valid grounds under Data Protection Law for us to do so (e.g. for the
          defense of legal claims);
        </p>
        <p>
          Objection:&nbsp;in certain circumstances, the right to restrict or
          object to our processing of your personal data (e.g. where you request
          correction or erasure, you also have a right to restrict processing of
          your applicable data while your request is considered);
        </p>
        <p>
          The right to withdraw your consent, where you have provided personal
          data voluntarily, or otherwise consented to its use; and
        </p>
        <p>the right to complain to a supervisory authority.</p>
        <p>
          You may exercise the rights set out at items by contacting us at
          support@onport.com.
        </p>
        <h2>H. UPDATES TO THIS PRIVACY POLICY</h2>
        <p>
          Any changes we may make to this privacy policy in the future will be
          posted on this page and, where appropriate, may be notified to you via
          e-mail. Please check back regularly to keep informed of updates or
          changes to this privacy policy.
        </p>
        <h2>I. CONTACT</h2>
        <p>
          Any questions, comments and requests regarding this privacy policy are
          welcomed and should be addressed to support@onport.com.
        </p>
      </Container>
    </Layout>
  )
}
